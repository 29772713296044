import { BROWSER_STORAGE_KEY_TOKEN, setItemToBrowserStorage, BROWSER_STORAGE_KEY_i18_LANG } from "./storage.utils"
import { ROUTER_CONFIG } from "../config/routing"
import { REDIRECT_TO } from "./Constants"
import { NAVIGATE_MEMBER_DASHBOARD } from "./urlConstants"
import { getCurrentPageUri } from "."
import { _URL_PARAMS_ACCESS_TOKEN, _URL_PARAMS_LANG } from "./urlParams.utils";
import { _WHITELIST_LANGUAGES } from "../config/config";
import { isPatternMatch } from "./validation.utils";

/**
 * @description Method to extract the token from the URL
 * @author Somdas M
 */
export const handleQueryParamsInURL = () => {
    const { hash } = window.location
    if (hash && hash.includes("?")) {
        let queryParams = hash.split("?")
        let _appUrl = queryParams[0]
        if (queryParams.length > 1) {
            queryParams = queryParams[1].split("&")
            queryParams.map(queryParam => {
                if (queryParam && queryParam.includes("=")) {
                    const param = queryParam.split("=")
                    if (param && param.length == 2) {
                        const pattern = ROUTER_CONFIG.urlParamConfig[param[0]] ? ROUTER_CONFIG.urlParamConfig[param[0]].pattern : ["."]
                        if (isPatternMatch(pattern, getCurrentPageUri())) {
                            switch (param[0]) {
                                case _URL_PARAMS_ACCESS_TOKEN:
                                    if(!ROUTER_CONFIG.config.isCookieEnabled){
                                        setItemToBrowserStorage(BROWSER_STORAGE_KEY_TOKEN, param[1]);
                                    }
                                    break;
                                case _URL_PARAMS_LANG:
                                    const language = _WHITELIST_LANGUAGES.find(l => l.code == param[1])
                                    if (language && Object.keys(language).length) {
                                        setItemToBrowserStorage(BROWSER_STORAGE_KEY_i18_LANG, JSON.stringify(language));
                                    }
                                    break;
                                default:
                                    if(_appUrl.includes("?")) {
                                        _appUrl += `&${param[0]}=${param[1]}`
                                    } else {
                                        _appUrl += `?${param[0]}=${param[1]}`
                                    }
                                    console.debug("received [",param[0],"]=[",param[1],"]")
                            }
                        }

                    }
                }
            })
            window.history.pushState({}, null, _appUrl)
        }
    }
}

/**
 * @description Method to set the redirection url
 * @author Somdas M
 */
export const handlePageRedirectionURL = () => {
    const currentPage = getCurrentPageUri()
    const pageConfig = ROUTER_CONFIG.routes.find(config => config.url == currentPage)
    if (pageConfig && pageConfig.isProtected) {
        const { hash } = window.location
        let redirectionUrl = hash && hash.includes("#") ? hash.split("#")[1] : ''
        window.location.href = `#${ROUTER_CONFIG.default.url}?${REDIRECT_TO}=${redirectionUrl}`
    }
}

/**
 * @description Method to redirect to the redirectTo url
 * @author Somdas M
 */
export const redirectPage = () => {
    let hasRedirectionUrl = false
    if (window.location.hash.includes("?")) {
        const queryParams = window.location.hash.split("?")
        let redirectionBaseUrl = '', params = ''
        let foundRedirectUrl = false
        queryParams.map((queryParam, idx) => {
            if (idx > 0) {
                if (queryParam.includes(REDIRECT_TO) && queryParam.includes("=") && !foundRedirectUrl) {
                    const param = queryParam.split("=")
                    redirectionBaseUrl = param[1] ? param[1] : ''
                    foundRedirectUrl = true
                } else {
                    params += queryParam
                }
            }
        })
        if (redirectionBaseUrl) {
            hasRedirectionUrl = true
            window.location = `#${redirectionBaseUrl}${params ? "?" + params : ""}`
        }
    } else if (!hasRedirectionUrl) {
        window.location = `#${NAVIGATE_MEMBER_DASHBOARD}`
    }
}