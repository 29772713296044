import { PROFILE_COMPLETENESS_PERCENTAGE } from "./actions";

const initialState = {
    completed: "0"
}

export function profileCompletenessPercentageReducer(state, action){
    const { type, payload } = action;
    if(!state) state = initialState
    if(type === PROFILE_COMPLETENESS_PERCENTAGE) {
        const completed = payload
        return {
            ...state,
            completed
        }
    } else{
        return state
    }
}