import { _URL_RETRIEVE_ACTIVITY_DETAIL,
     _URL_RECOMMENDED_PARTNERS,
      _URL_RECOMMENDED_BENEFITS
} from "../../common/config/config";
import { getApiErrorMessage } from "../../common/utils";
import { doPost } from "../../common/utils/api";
import { ERROR } from "../../common/middleware/redux/commonAction";
import { startApiLoading, stopApiLoading } from "../../common/components/fieldbank/loader/action";

export const RECENT_ACTIVITY_DETAIL = "RECENT_ACTIVITY_DETAIL"
export const RECOMMENDED_PARTNERS_DETAIL = "RECOMMENDED_PARTNERS_DETAIL"
export const RECOMMENDED_BENEFITS_DETAIL = "RECOMMENDED_BENEFITS_DETAIL"
/**
 * Action call to retrieve activity details.
 * @param {JSON} payload Request payload to be dispatched
 * @author Somdas M
 */
export const fetchActivityDetails = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("fetchActivityDetails"))
        await doPost(_URL_RETRIEVE_ACTIVITY_DETAIL, payload)
            .then((response) => {
                dispatch(stopApiLoading("fetchActivityDetails"))
                dispatch({
                    type: RECENT_ACTIVITY_DETAIL,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchActivityDetails"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
                dispatch({
                    type: RECENT_ACTIVITY_DETAIL,
                    payload: { object: {}}
                })
            })
    };
}

export const fetchRecommededPartnersDetails = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("fetchActivityDetails"))
        await doPost(_URL_RECOMMENDED_PARTNERS, payload)
        .then((response) => {
            dispatch(stopApiLoading("fetchActivityDetails"))
            dispatch({
                type: RECOMMENDED_PARTNERS_DETAIL,
                payload: response.data
            })
        })
        .catch((error) => {
            dispatch(stopApiLoading("fetchActivityDetails"))
            dispatch({
                type: ERROR,
                payload: { error: getApiErrorMessage(error.response.data.error) }
            })
            dispatch({
                type: RECOMMENDED_PARTNERS_DETAIL,
                payload: { object: {}}
            })
        })
    }
}

export const fetchRecommededBenefitsDetails = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("fetchActivityDetails"))
        await doPost(_URL_RECOMMENDED_BENEFITS, payload)
        .then((response) => {
            dispatch(stopApiLoading("fetchActivityDetails"))
            dispatch({
                type: RECOMMENDED_BENEFITS_DETAIL,
                payload: response.data
            })
        })
        .catch((error) => {
            dispatch(stopApiLoading("fetchActivityDetails"))
            dispatch({
                type: ERROR,
                payload: { error: getApiErrorMessage(error.response.data.error) }
            })
            dispatch({
                type: RECOMMENDED_BENEFITS_DETAIL,
                payload: { object: {}}
            })
        })
    }
}