import { getApiErrorMessage } from "../../utils"
import { _URL_CREATE_SECURITY_TOKEN, _URL_VALIDATE_SECURITY_TOKEN } from "../../config/config"
import { doPost } from "../../utils/api"

export const MFA_SECURITY_TOKEN = "MFA_SECURITY_TOKEN"
export const MFA_VALIDATE_SECURITY_TOKEN = "MFA_VALIDATE_SECURITY_TOKEN"
export const MFA_RESPONSE = "MFA_RESPONSE"
export const createSecurityToken = (payload) => {
    return async dispatch => {
        await doPost(_URL_CREATE_SECURITY_TOKEN, payload).then(response=>{
            dispatch({
                type: MFA_SECURITY_TOKEN,
                payload: response.data.statusMessage
            })
            dispatch({
                type: MFA_RESPONSE,
                payload: {type: "success", msg: "mfa.code_sent"}
            })
        }).catch(error=>{
            dispatch({
                type: MFA_RESPONSE,
                payload: {type: "danger", msg: getApiErrorMessage(error.response.data.error)}
            })
        })
    }
}

export const validateToken = (payload) => {
    return async dispatch => {
        await doPost(_URL_VALIDATE_SECURITY_TOKEN, payload).then(response=>{
            const { object } = response.data
            if(object.status === 'true'){
                dispatch({
                    type: MFA_RESPONSE,
                    payload: {type: "success", msg: "mfa.verified"}
                })
                dispatch({
                    "type": MFA_VALIDATE_SECURITY_TOKEN,
                    "payload": object.status === 'true'
                })
            } else {
                dispatch({
                    type: MFA_RESPONSE,
                    payload: {type: "danger", msg: "mfa.verification_failed"}
                })
            }
        }).catch(error=>{
            dispatch({
                type: MFA_RESPONSE,
                payload: {type: "danger", msg: getApiErrorMessage(error.response.data.error)}
            })
        })
    }
}