import { doPost } from '../../common/utils/api'
import {
    _URL_SEARCH_MEMBER,
    _URL_ADD_FAMILY_MEMBER
} from '../../common/config/config'
import { startButtonSpinner, stopButtonSpinner } from '../../common/components/fieldbank/loader/action';
import { getApiErrorMessage } from '../../common/utils';
// Action Type
export const NEW_FAMILY_MEMBER_SEARCH = "NEW_FAMILY_MEMBER_SEARCH"
export const NEW_FAMILY_MEMBER_SEARCH_ERROR = "NEW_FAMILY_MEMBER_SEARCH_ERROR"
export const ADD_FAMILY_MEMBER = "ADD_FAMILY_MEMBER"
export const ADD_FAMILY_MEMBER_ERROR = "ADD_FAMILY_MEMBER_ERROR"
export const ADD_FAMILY_CONFIG = "ADD_FAMILY_CONFIG"
export const ADD_FAMILY_CONFIG_ERROR = "ADD_FAMILY_CONFIG_ERROR"


export const searchMember = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "searchMember"))
        await doPost(_URL_SEARCH_MEMBER, payload)
            .then(response => {
                dispatch(stopButtonSpinner(id, "searchMember"))
                dispatch({
                    type: NEW_FAMILY_MEMBER_SEARCH,
                    payload: response.data
                })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "searchMember"))
                dispatch({
                    type: NEW_FAMILY_MEMBER_SEARCH_ERROR,
                    payload: {error: getApiErrorMessage(error.response.data.error)}
                })
            })
    }
}

export const addFamilyMember = (payload, id) => {
    return dispatch =>{
        dispatch(startButtonSpinner(id, "addFamilyMember"))
        doPost(_URL_ADD_FAMILY_MEMBER, payload)
            .then(response => {
                dispatch(stopButtonSpinner(id, "addFamilyMember"))
                dispatch({
                    type: ADD_FAMILY_MEMBER,
                    payload: response.data
                })
                dispatch({
                    type: ERROR,
                    payload: {error: ['add_family_member.add_existing_member.successMessage']}
                })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "addFamilyMember"))
                dispatch({
                    type: ADD_FAMILY_MEMBER_ERROR,
                    //payload: {error: getApiErrorMessage(error.response.data.error)}
                    payload: error.response.data

                })
            })
        }

}