import { RECENT_ACTIVITY_DETAIL,
     RECOMMENDED_PARTNERS_DETAIL,
     RECOMMENDED_BENEFITS_DETAIL
} from "./actions";

/**
 * Reducer for the page dashboard and promotions
 * @param {Object} state Initialised as empty object
 * @param {Object} action Object that contains request payload and action
 */
export function recentActivityReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    
    if(type == RECENT_ACTIVITY_DETAIL){
        return {
            ...state,
            recentActivity: payload.object
        }
    }
    return state
}

export function offersPartnersReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    
    if(type == RECOMMENDED_PARTNERS_DETAIL){
        return {
            ...state,
            recommendedPartners: payload.object
        }
    }else if(type == RECOMMENDED_BENEFITS_DETAIL){
        return {
            ...state,
            recommendedBenefits: payload.object
        }
    }
    return state
}