import {
    ACTION_RETRIEVE_NOMINEES_SUCCESS,
    ACTION_RETRIEVE_CUSTOMER_SUCCESS,
    ACTION_ADD_TRAVELLER_SUCCESS,
    ACTION_ADD_NOMINEE_SUCCESS,
    ACTION_UPDATE_TRAVELLER_SUCCESS,
    ACTION_UPDATE_NOMINEE_SUCCESS,
    ACTION_DELETE_TRAVELLER_SUCCESS,
    ACTION_DELETE_NOMINEE_SUCCESS
} from "./action"

/**
 * Reducer for retrieving nominees
 * @param {*} state 
 * @param {*} action action with type and payload
 * 
 * @author Ajmal V Aliyar
 */
export const retrieveNomineesReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_RETRIEVE_NOMINEES_SUCCESS) {
        return { response: payload }
    } else {
        return state
    }
}

/**
 * Reducer for retrieving customer
 * @param {*} state 
 * @param {*} action action with type and payload
 * 
 * @author Ajmal V Aliyar
 */
export const retrieveCustomerReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_RETRIEVE_CUSTOMER_SUCCESS) {
        return { response: payload }
    } else {
        return state
    }
}

export const addTravellerReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_ADD_TRAVELLER_SUCCESS) {
        return { response: payload }
    } else {
        return state
    }
}

export const addNomineeReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_ADD_NOMINEE_SUCCESS) {
        return { response: payload }
    } else {
        return state
    }
}

export const updateTravellerReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_UPDATE_TRAVELLER_SUCCESS) {
        return { response: payload }
    } else {
        return state
    }
}

export const updateNomineeReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_UPDATE_NOMINEE_SUCCESS) {
        return { response: payload }
    } else {
        return state
    }
}

export const deleteTravellerReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_DELETE_TRAVELLER_SUCCESS) {
        return { response: payload }
    } else {
        return state
    }
}

export const deleteNomineeReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_DELETE_NOMINEE_SUCCESS) {
        return { response: payload }
    } else {
        return state
    }
}