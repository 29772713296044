import { SET_CHANGE_PASSWORD } from './actions';
const initialState = {};

export default function setChangePswData(state, action) {
    const { type, payload } = action;
    if(!state) state = initialState
    if(type === SET_CHANGE_PASSWORD) {
        return {
            changePswInfo: payload
        }
    } else {
        return state;
    }
}