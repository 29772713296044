import { ACTION_ADD_ACCOUNT_USER, ACTION_ADD_ACCOUNT_USER_SUCCESS, ACTION_DELETE_ACCOUNT_USER_SUCCESS, ACTION_RETRIEVE_ACCOUNT_USERS_SUCCESS, ACTION_UPDATE_ACCOUNT_USER_DELETE, ACTION_UPDATE_ACCOUNT_USER_PRIVILEGE, ACTION_UPDATE_ACCOUNT_USER_PRIVILEGE_SUCCESS } from "./Constants"

/**
 * Reducer for retrieving nominees
 * @param {*} state 
 * @param {*} action action with type and payload
 * 
 * @author Amrutha
 */
export const retrieveAccountUsersReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_RETRIEVE_ACCOUNT_USERS_SUCCESS) {
        return { retrieveAccountUsers: payload }
    } else {
        return state
    }
}


/**
 * Reducer for Add account user
 * @param {*} state 
 * @param {*} action action with type and payload
 * 
 * @author Amrutha
 */
export const addAccountUserReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_ADD_ACCOUNT_USER) {
        return { response: payload }
    } else {
        return state
    }
}

/**
 * Reducer for Update account user privileges
 * @param {*} state 
 * @param {*} action action with type and payload
 * 
 * @author Amrutha
 */
export const updateAccountUserPrivilegeReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_UPDATE_ACCOUNT_USER_PRIVILEGE) {
        return { response: payload }
    }

    else {
        return state
    }
}

/**
 * Reducer to delete account user
 * @param {*} state 
 * @param {*} action action with type and payload
 * 
 * @author Amrutha
 */
export const deleteAccountUserReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    if (type == ACTION_UPDATE_ACCOUNT_USER_DELETE) {
        return { response: payload }
    }
    else {
        return state
    }
}