import { doPost } from '../../common/utils/api'
import 'regenerator-runtime/runtime';
import {
    _URL_TRANSACTION_AUTHENTICATE,
    _URL_TRANSACTION_LOGOUT,
    _URL_BUY_POINT,
    _URL_ACCEPT_PAYMENT,
    _URL_SIMULATE_RULE,
    _URL_TIER_UPGRADE,
    _URL_GIFT_POINTS
} from '../../common/config/config'
import { stopButtonSpinner, startButtonSpinner } from '../../common/components/fieldbank/loader/action';
import { ERROR } from '../promotions/actions';

// Action Type
export const FETCH_TRANSFER_AMOUNT_LOOKUP_ERROR = "FETCH_TRANSFER_AMOUNT_LOOKUP_ERROR"
export const TRANSACTION_AUTHENTICATE_SUCCESS = "TRANSACTION_AUTHENTICATE_SUCCESS"
export const TRANSACTION_AUTHENTICATE_FAILED = "TRANSACTION_AUTHENTICATE_FAILED"
export const BUY_POINT_SUCCESS = "BUY_POINT_SUCCESS"
export const BUY_POINT_FAILED = "BUY_POINT_FAILED"
export const BUY_POINT_ACCEPT_PAYMENT_SUCCESS = "BUY_POINT_ACCEPT_PAYMENT_SUCCESS"
export const BUY_POINT_ACCEPT_PAYMENT_FAILED = "BUY_POINT_ACCEPT_PAYMENT_FAILED"
export const TRANSACTION_AUTHENTICATE_LOGOUT_SUCCESS = "TRANSACTION_AUTHENTICATE_LOGOUT_SUCCESS"
export const TRANSACTION_AUTHENTICATE_LOGOUT_FAILED = "TRANSACTION_AUTHENTICATE_LOGOUT_FAILED"
export const PAYMENT_INIT_FAILED = "PAYMENT_INIT_FAILED"
export const LOAD_CONFIG_SUCCESS = "LOAD_CONFIG_SUCCESS"
export const LOAD_CONFIG_ERROR = "LOAD_CONFIG_ERROR"
export const SIMULATE_RULE_SERVICE = "SIMULATE_RULE_SERVICE"
export const TIER_UPGRADE_SUCCESS = "TIER_UPGRADE_SUCCESS"
export const GIFT_POINTS_SUCCESS = "GIFT_POINTS_SUCCESS"

export const authenticateTransaction = (payload, id) => {
    return async (dispatch) => {
        dispatch(startButtonSpinner(id, "authenticateTransaction"))
        await doPost(_URL_TRANSACTION_AUTHENTICATE, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "authenticateTransaction"))
                dispatch({
                    type: TRANSACTION_AUTHENTICATE_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "authenticateTransaction"))
                dispatch({
                    type: TRANSACTION_AUTHENTICATE_FAILED,
                    payload: error.response.data
                })
            })
    }
}


export const buyPointAction = (payload, id) => {
    return async (dispatch) => {
        dispatch(startButtonSpinner(id, "buyPointAction"))
        await doPost(_URL_BUY_POINT, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "buyPointAction"))
                dispatch({
                    type: BUY_POINT_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "buyPointAction"))
                dispatch({
                    type: BUY_POINT_FAILED,
                    payload: error.response.data
                })
            })
    }
}

export const buyPointAcceptPayment = (payload, id) => {
    return async(dispatch) => {
        dispatch(startButtonSpinner(id, "buyPointAcceptPayment"))
        await doPost(_URL_ACCEPT_PAYMENT, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "buyPointAcceptPayment"))
                dispatch({
                    type: BUY_POINT_ACCEPT_PAYMENT_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "buyPointAcceptPayment"))
                dispatch({
                    type: BUY_POINT_ACCEPT_PAYMENT_FAILED,
                    payload: error.response.data
                })
            })
    }
}

export const transactionLogout = (payload, id) => {
    return async (dispatch) => {
        dispatch(startButtonSpinner(id, "transactionLogout"))
        await doPost(_URL_TRANSACTION_LOGOUT, payload)
        .then((response) => {
                dispatch(stopButtonSpinner(id, "transactionLogout"))
                dispatch({
                    type: TRANSACTION_AUTHENTICATE_LOGOUT_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "transactionLogout"))
                dispatch({
                    type: TRANSACTION_AUTHENTICATE_LOGOUT_FAILED,
                    payload: error.response.data
                })
            })
    }
}

export const simulateRuleService = (payload, id) => {
    return async (dispatch) => {
        dispatch(startButtonSpinner(id, "simulateRuleService"))
        await doPost(_URL_SIMULATE_RULE, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "simulateRuleService"))
                dispatch({
                    type: SIMULATE_RULE_SERVICE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "simulateRuleService"))
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export const tierUpgrade = (payload, id) => {
    return async (dispatch) => {
        dispatch(startButtonSpinner(id, "tierUpgrade"))
        await doPost(_URL_TIER_UPGRADE, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "tierUpgrade"))
                dispatch({
                    type: TIER_UPGRADE_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "tierUpgrade"))
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}

export const giftPoints = (payload, id) => {
    return async (dispatch) => {
        dispatch(startButtonSpinner(id, "giftMiles"))
        await doPost(_URL_GIFT_POINTS, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, "giftMiles"))
                dispatch({
                    type: GIFT_POINTS_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "giftMiles"))
                dispatch({
                    type: ERROR,
                    payload: error.response.data
                })
            })
    }
}
