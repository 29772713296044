import { ACTIVATE } from "./action";

export const activateReducer = (state={}, action) => {
    const { type, payload } = action;
    switch(type) {
        case ACTIVATE:
            return payload.object
        default:
            return state;
    }
}