import { toUpperCase } from "../../common/utils";

export const getOnetimeKey = (code, locale, languageCode) => {
    if (code) {
        return locale ? code.concat("_").concat(toUpperCase(languageCode)) : code
    }
    return ""
}

export const getAttributeCode = (code, locale, languageCode) => {
    if (code) {
        return locale ? code.replace("_".concat(toUpperCase(languageCode)), "") : code
    }
    return ""
}