
import { doPostResetPassword } from '../../common/utils/api'
import { _URL_RESET_PSW_DETAILS } from '../../common/config/config'
import { stopButtonSpinner, startButtonSpinner } from '../../common/components/fieldbank/loader/action';
import { getApiErrorMessage } from '../../common/utils';


export const SET_RESET_PASSWORD = "SET_RESET_PASSWORD";

/**
 * Creates Action of type <i>_URL_RESET_PSW_DETAILS</i> with given data
 *
 * @function resetPassword
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_RESET_PSW_DETAILS</i>
 *
 */
export const resetPasswordDetails = (payload, id) => {
  return async dispatch => {
    dispatch({
      type: SET_RESET_PASSWORD,
      payload: {}
    });
    dispatch(startButtonSpinner(id, "resetPasswordDetails"))
    await doPostResetPassword(_URL_RESET_PSW_DETAILS, payload).then((res) => {
      dispatch(stopButtonSpinner(id, "resetPasswordDetails"))
      if(res.data)
      {
        dispatch({
          type: SET_RESET_PASSWORD,
          payload: res.data
      });
      }
      }).catch((error) => {
        dispatch(stopButtonSpinner(id, "resetPasswordDetails"))
        dispatch({
          type: SET_RESET_PASSWORD,
          payload: error.response?error.response.data:{}
        })
    })
  };
}

