import { SET_LOGIN_DATA } from './actions';
const initialState = {};

export default function setLoginData(state, action) {
    const { type, payload } = action;
    if(!state) state = initialState

    if(type == SET_LOGIN_DATA){
        return {
            loginInfo: payload
        }
    } else{
        return state;
    }
}