import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    toTitleCase,
    toUpperCase,
    withSuspense
} from '../../../common/utils';
import Loader from '../../../common/components/fieldbank/loader/Loader';
import { BROWSER_STORAGE_KEY_MEMBERSHIP_NO, getItemFromBrowserStorage } from '../../utils/storage.utils';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import { _IMAGE_BASEURL } from '../../config/config';
import moment from 'moment'
import { HYPHEN } from './Constants';
import { CONFIG_SECTION_DASHBOARD, CONFIG_SECTION_ACCOUNT_SUMMARY, CONFIG_SECTION_PROFILE } from '../../utils/Constants';
import { fetchConfiguration } from '../../../common/middleware/redux/commonAction';

class DigitalCard extends Component {
    constructor(props) {
        super(props)
        this.downloadCard = this.downloadCard.bind(this)
        this.myRef = React.createRef();
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (!this.props.config) {
            this.props.fetchConfiguration(CONFIG_SECTION_DASHBOARD)
        }
        if(!this.props.profileConfig){
            this.props.fetchConfiguration(CONFIG_SECTION_PROFILE);
        }
    }

    downloadCard = (config) => {
        if (config) {
            const currentTimeStamp = moment().valueOf()
            const fileNameFromConfig = config.ui && config.ui.digitalCard && config.ui.digitalCard.cardName
            const fileExtension = config.ui && config.ui.digitalCard && config.ui.digitalCard.cardExtension
            const downloadFileName = fileNameFromConfig + HYPHEN + currentTimeStamp + fileExtension
            this.setState({
                isLoading: true
            })
            const digitalCard = this.myRef.current;
            const pngProperties = (config.ui &&
                config.ui.digitalCard &&
                config.ui.digitalCard.pngProperties) ?
                config.ui.digitalCard.pngProperties : {}
            htmlToImage.toPng(digitalCard, pngProperties)
                .then((dataUrl) => {
                    this.setState({
                        isLoading: false
                    })
                    download(dataUrl, downloadFileName);

                })
                .catch(function (error) {
                    console.error('Downloading failed', error);
                    this.setState({
                        isLoading: false
                    })
                });
        }
    }
    render() {
        const { t, accountSummary, qrcodeImage, config, profileData, memberTierDetails,profileConfig } = this.props
        const membershipNumber = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO)
        const downloadButtonStyle = {
            visibility: `${this.state.isLoading ? 'hidden' : 'visible'}`
        }
        let displayNameView = profileConfig && profileConfig.ui && profileConfig.ui.displayNameView ? profileConfig.ui.displayNameView:[]; 
        const individualInfo = profileData && profileData.object && profileData.object.memberAccount && profileData.object.memberAccount.memberProfile &&
        profileData.object.memberAccount.memberProfile.individualInfo && profileData.object.memberAccount.memberProfile.individualInfo;
        let displayName ="";
        displayNameView.forEach(view=>{
            displayName += (individualInfo && individualInfo[view] ? individualInfo[view] : "") + " ";
        })
        // const displayName = profileData && profileData.object && profileData.object.memberAccount && profileData.object.memberAccount.memberProfile &&
        //     profileData.object.memberAccount.memberProfile.individualInfo && profileData.object.memberAccount.memberProfile.individualInfo.displayName
        
        return (
            <div className="modal fade modal--digital" id="digital-card-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal--digital__title" id="exampleModalLongTitle">{t("sidebar.mileage_card.title")}</h5>
                            <p className="modal--digital__desc">{t("sidebar.mileage_card.description")}</p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <img src={`${_IMAGE_BASEURL}/icons/icon-close.svg`} alt="Close Digital Card Popup Button" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="digital-card" ref={this.myRef}>
                                    <div className="digital-card__bg"></div>
                                        <div className="digital-card__status">
                                            <strong className="text">
                                                {
                                                memberTierDetails && memberTierDetails.tier1 ?
                                                memberTierDetails.tier1.type : ""
                                                }
                                            </strong>
                                            <strong className="type">
                                                {
                                                memberTierDetails && memberTierDetails.tier1 ?
                                                memberTierDetails.tier1.name : ""
                                                }
                                            </ strong>
                                        </div>
                                        <div className="digital-card__info">
                                            <span className="name">{toTitleCase(displayName)}</span>
                                            <span className="number">{(membershipNumber) ? membershipNumber : ''}</span>
                                            {
                                                accountSummary
                                                && (accountSummary.tierToDate) &&
                                                <span className="date">{t("sidebar.mileage_card.expiry_date")}: {accountSummary.tierToDate}</span>
                                            }
                                        </div>
                                        <div className="digital-card__blck">
                                            <div className="bar-code">{(qrcodeImage && qrcodeImage.qrCode) ? <img src={qrcodeImage.qrCode} width="100" alt="QR Code" /> : <Loader />}</div>
                                            <div className="logo"><img src={`${_IMAGE_BASEURL}/logo-card.png`} width="181" alt="ifly-logo" /></div>
                                        </div>
                                        {
                                            memberTierDetails && memberTierDetails.tier2 &&
                                            <div className="cobrand">
                                                <span>{memberTierDetails.tier2.type} </span>
                                                <strong className="text-uppercase"> {memberTierDetails.tier2.name}</strong>
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.isLoading && <Loader className="download-spinner" />
                                    }
                                    <div className="utils-wrap" style={downloadButtonStyle}>
                                        <a className="btn btn-secondary" role="button" data-test="downloadCardTest" onClick={() => this.downloadCard(config)}>
                                            <i class="fa fa-download" aria-hidden="true"></i>
                                            <span>{t("sidebar.mileage_card.download")}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {
        accountSummary: state.accountSummaryReducer.accountSummary,
        qrcodeImage: state.qrCodeImageReducer.qrImage,
        config: state.configurationReducer[CONFIG_SECTION_DASHBOARD],
        profileData: state.profileDataReducer.profileData,
        memberTierDetails : state.setTierDetailsReducer.payload,
        accountSummaryConfig: state.configurationReducer[CONFIG_SECTION_ACCOUNT_SUMMARY],
        profileConfig: state.configurationReducer[CONFIG_SECTION_PROFILE]
    }
}

const mapDispatchToProps = {
    fetchConfiguration
}

export default withSuspense()(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DigitalCard)));