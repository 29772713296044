
import { doPost } from '../../common/utils/api'
import {
  _URL_RETRIEVE_RETRO_CLAIM
} from '../../common/config/config';


export const RETRIEVE_RETRO_CLAIM_SUCCESS = "RETRIEVE_RETRO_CLAIM_SUCCESS";
export const RETRIEVE_RETRO_CLAIM_ERROR = "RETRIEVE_RETRO_CLAIM_ERROR";
export const CONFIG_CLAIM_SUMMARY_SUCCESS = "CONFIG_CLAIM_SUMMARY_SUCCESS";
export const CONFIG_CLAIM_SUMMARY_FAILURE = "CONFIG_CLAIM_SUMMARY_FAILURE";

export const retrieveRetroClaimRequests = (payload) => {
  return async dispatch => {
    await doPost(_URL_RETRIEVE_RETRO_CLAIM, payload)
      .then((response) => {
        dispatch({
          type: RETRIEVE_RETRO_CLAIM_SUCCESS,
          payload: response.data
        })
      })
      .catch((error) => {
        dispatch({
          type: RETRIEVE_RETRO_CLAIM_ERROR,
          payload: error
        })
      })
  };
}