import config from 'config';

export const _URL_ENV_CONFIG_URL = config.envConfigUrl;
// URL
export let _URL_BUY_PAYMENT_REDIRECT = config.url.self.buy_payment_redirect;
export let _URL_BUY_PAYMENT_FAILED = config.url.self.buy_payment_failed;
export let _URL_BUY_PAYMENT_SUCCESS = config.url.self.buy_payment_success;
export let _URL_BUY_PAYMENT_CANCELLED = config.url.self.buy_payment_cancelled;
export let _URL_TIERUPGRADE_PAYMENT_REDIRECT = config.url.self.tierupgrade_payment_redirect;
export let _URL_TIERUPGRADE_PAYMENT_FAILED = config.url.self.tierupgrade_payment_failed;
export let _URL_TIERUPGRADE_PAYMENT_SUCCESS = config.url.self.tierupgrade_payment_success;
export let _URL_TIERUPGRADE_PAYMENT_CANCELLED = config.url.self.tierupgrade_payment_cancelled;
export let _URL_GIFTPOINT_PAYMENT_REDIRECT = config.url.self.gift_point_redirect;
export let _URL_GIFTPOINT_PAYMENT_FAILED = config.url.self.gift_point_payment_failed;
export let _URL_GIFTPOINT_PAYMENT_SUCCESS = config.url.self.gift_point_payment_success;
export let _URL_GIFTPOINT_PAYMENT_CANCELLED = config.url.self.gift_point_payment_cancelled;
export let _URL_TRANSFER_PAYMENT_REDIRECT = config.url.self.transfer_payment_redirect;
export let _URL_TRANSFER_PAYMENT_FAILED = config.url.self.transfer_payment_failed;
export let _URL_TRANSFER_PAYMENT_SUCCESS = config.url.self.transfer_payment_success;
export let _URL_TRANSFER_PAYMENT_CANCELLED = config.url.self.transfer_payment_cancelled;
export let _URL_CALLBACK = config.url.self.callback_url;
export let _URL_REFFERAL = config.url.self.refferal;
export let _URL_FB_REFERRAL = config.url.self.fb_referral;
export let _URL_TWITTER_REFERRAL = config.url.self.twitter_referral;
export let _URL_PAYMENT_EXTEND_RENEW_REDIRECT = config.url.self.extend_renew_redirect;
export let _URL_PAYMENT_EXTEND_RENEW_FAILED = config.url.self.extend_renew_payment_failed;
export let _URL_PAYMENT_EXTEND_RENEW_SUCCESS = config.url.self.extend_renew_payment_success;
export let _URL_PAYMENT_EXTEND_RENEW_CANCELLED = config.url.self.extend_renew_payment_cancelled;

// Business Config Service URLs
export let _URL_CONFIGURATION_SERVICE = config.url.business_config_service.config_url;

// Authentication Service URLs
export let _URL_AUTH = config.url.auth_service.auth;
export let _URL_LOGOUT = config.url.auth_service.logout;
export let _URL_LOGIN_DETAILS = config.url.auth_service.fetch_login_details;
export let _URL_SOCIAL_LOGIN = config.url.auth_service.social_login_url;
export let _URL_SOCIAL_LOGGED_USER = config.url.auth_service.social_logged_user;
export let _URL_FETCH_REFRESH_TOKEN = config.url.auth_service.fetch_refresh_token;
export let _URL_SOCIAL_LOGIN_FACEBOOK = config.url.auth_service.social_login_url_facebook;
export let _URL_SOCIAL_LOGIN_GOOGLE = config.url.auth_service.social_login_url_google;

// Master Service URLs
export let _URL_MASTER_RETRIEVE = config.url.master_service.master_retrieve;
export let _URL_ADDITIONAL_DETAILS = config.url.master_service.additional_details;

// Member Service URLs
export let _URL_ENROLMENT = config.url.member_service.enrolment;
export let _URL_FAMILY_ENROLMENT = config.url.member_service.family_enrolment;
export let _URL_ENROLMENT_CORPORATE = config.url.member_service.enrolment_corporate;
export let _URL_PREAFFILIATED_ENROLMENT = config.url.member_service.preaffiliated_enrolment;
export let _URL_CHECK_MEMBERSHIP_NUMBER = config.url.member_service.membership_number_check;
export let _URL_CHANGE_PIN_DETAILS = config.url.member_service.pin_change;
export let _URL_USER_ACTIVATION = config.url.member_service.useractivation;
export let _URL_ADD_TRAVELLER = config.url.member_service.add_traveller;
export let _URL_UPDATE_NOMINEE = config.url.member_service.update_nominee;
export let _URL_DELETE_NOMINEE = config.url.member_service.delete_nominee;
export let _URL_FETCH_QRCODE_IMAGE = config.url.member_service.qrcode_image;
export let _URL_ADD_FAMILY_MEMBER = config.url.member_service.add_family_member;
export let _URL_FORGOT_PSW_LINK = config.url.member_service.forgot_psw_link;
export let _URL_CHANGE_PSW_DETAILS = config.url.member_service.change_psw_link;
export let _URL_ACCOUNT_SUMMARY = config.url.member_service.account_summary;
export let _URL_DELETE_TRAVELLER = config.url.member_service.delete_traveller;
export let _URL_UPDATE_TRAVELLER = config.url.member_service.update_traveller;
export let _URL_ADD_NOMINEE = config.url.member_service.add_nominee;
export let _URL_RETRIEVE_NOMINEES = config.url.member_service.retrieve_nominees;
export let _URL_PREFERENCES_UPDATE = config.url.member_service.update_preference;
export let _URL_RETRIEVE_CUSTOMER_PROFILE = config.url.member_service.retrieve_customer_profile;
export let _URL_ADD_PROFILE_PICTURE = config.url.member_service.add_profile_picture;
export let _URL_FETCH_FAMILY_MEMBERS = config.url.member_service.fetch_family_members;
export let _URL_UPDATE_PROFILE_DETAILS = config.url.member_service.update_profile_details;
export let _URL_FETCH_PROFILE_DETAILS = config.url.member_service.fetch_profile_details;
export let _URL_RETRIEVE_TRAVELLERS = config.url.member_service.retrieve_travellers;
export let _URL_RESET_PSW_DETAILS = config.url.member_service.reset_password;
export let _URL_FETCH_PROFILE_IMAGE = config.url.member_service.fetch_profile_image;
export let _URL_UPDATE_CUSTOMER_PROFILE = config.url.member_service.update_customer_profile;
export let _URL_RETRIEVE_ACCOUNT_USERS = config.url.member_service.retrieve_account_users;
export let _URL_UPDATE_SECURITY_QUESTIONS = config.url.member_service.update_security_questions;
export let _URL_CREATE_SECURITY_TOKEN = config.url.member_service.create_security_token;
export let _URL_VALIDATE_SECURITY_TOKEN = config.url.member_service.validate_security_token;
export let _URL_VALIDATE_MEMBER_DETAIL = config.url.member_service.validate_member_detail;
export let _URL_UPDATE_ACCOUNT_USER_PRIVILEGE = config.url.member_service.update_account_user_privilege;
export let _URL_ADD_COMPANION = config.url.member_service.add_companion;
export let _URL_REFERRAL = config.url.member_service.referral;
export let _URL_ADD_DELETE_NOMINEE = config.url.member_service.add_delete_nominee;
export let _URL_ACCOUNT_USER_ACTIVATION = config.url.member_service.account_user_activation;
export let _URL_EMAIL_ACTIVATION = config.url.member_service.activate_email;
export let _URL_CHANGE_EMAIL = config.url.member_service.change_email;


// Transaction Service URLs
export let _URL_BUY_POINT = config.url.transaction_service.buy_point;
export let _URL_TIER_UPGRADE = config.url.transaction_service.tier_upgrade;
export let _URL_SEARCH_MEMBER = config.url.transaction_service.search_member;
export let _URL_GIFT_MILES = config.url.transaction_service.gift_miles;
export let _URL_GIFT_POINTS = config.url.transaction_service.gift_point;
export let _URL_ACCEPT_PAYMENT = config.url.transaction_service.accept_payment;
export let _URL_TRANSFER_POINT = config.url.transaction_service.transfer_point;
export let _URL_REQUEST_CLAIM = config.url.transaction_service.request_claim;
export let _URL_MIELAGE_CALCULATOR = config.url.transaction_service.mileage_calculator;
export let _URL_TRANSACTION_LOGOUT = config.url.transaction_service.transaction_logout;
export let _URL_TRANSACTION_AUTHENTICATE = config.url.transaction_service.transaction_authenticate;
export let _URL_MIELAGE_CALCULATOR_EARN = config.url.transaction_service.mileage_calculator_earn;
export let _URL_MIELAGE_CALCULATOR_BURN = config.url.transaction_service.mileage_calculator_burn;
export let _URL_RETRIEVE_RETRO_CLAIM = config.url.transaction_service.fetch_claim_summary
export let _URL_RETRIEVE_QUOTE = config.url.transaction_service.fetch_retrieve_quote
export let _URL_RENEW_MILES_PAYMENT = config.url.transaction_service.renew_miles_payment

// Summary Service URLs
export let _URL_CONVERT_POINTS = config.url.summary_service.convertpoints;
export let _URL_FETCH_FLIGHTS = config.url.summary_service.fetch_flights;
export let _URL_TRANSACTION_SUMMARY = config.url.summary_service.transaction_summary;
export let _URL_FEED_BACK_CREATION = config.url.summary_service.fetch_feedback_details;
export let _URL_RETRIEVE_ACTIVITY_DETAIL = config.url.summary_service.retrieve_activity_detail;
export let _URL_VIEW_NOTIFICATIONS = config.url.summary_service.view_notifications;
export let _URL_RETRIEVE_RENEW_EXPIRY_POINT_DETAILS = config.url.summary_service.retrieve_renew_expiry_point_details;
export let _URL_SUBSCRIBE = config.url.summary_service.subscribe;
export let _URL_UNSUBSCRIBE = config.url.summary_service.unsubscribe;
export let _URL_FETCH_AIRPORT_DETAILS = config.url.summary_service.fetch_airport_details;
export let _URL_SIMULATE_RULE = config.url.summary_service.simulate_rule

// CMS URLs
export let _URL_CMS = config.url.cms_service.cms;
export let _URL_CMS_API = config.url.cms_service.cms_api;
export let _URL_CMS_HEADER_FOOTER_CONTENT = config.url.cms_service.header_footer_content;
export let _URL_RECOMMENDED_PARTNERS = config.url.cms_service.recommended_partners;
export let _URL_RECOMMENDED_BENEFITS = config.url.cms_service.recommended_benefits;
export let _URL_VIEW_OFFERPARTNERDETAIL = config.url.cms_service.view_offerpartnerdetail;

// Other URLs
export let _URL_GULF_AIR = config.url.others.gulfAirUrl;
export let _URL_PSS_SEARCH_FLIGHTS = config.url.saber.search_flights;
export let _URL_PSS_MODIFY_SEARCH = config.url.saber.modify_search;
export let _URL_PSS_LIST_FLIGHTS = config.url.saber.list_flights;

// Internalization
export let _URL_I18N_MULTI_LANG = config.i18n.url;
export let _WHITELIST_LANGUAGES = config.i18n.languages;
export let _NAMESPACE = config.i18n.namespace;
export let _DEFAULT_LANGUAGE = config.i18n.default_lng;
export let _DEFAULT_NAMESPACE = config.i18n.default_ns

export let _IMAGE_BASEURL = config.imageBaseUrl
export let _CHATBOT_CONFIG = config.chatbot
export let _COMPANY_CODE = config.companyCode
export let _PROGRAM_CODE = config.programCode

export const setUrl = (config) => {
    _URL_BUY_PAYMENT_REDIRECT = config.url.self.buy_payment_redirect;
    _URL_BUY_PAYMENT_FAILED = config.url.self.buy_payment_failed;
    _URL_BUY_PAYMENT_SUCCESS = config.url.self.buy_payment_success;
    _URL_BUY_PAYMENT_CANCELLED = config.url.self.buy_payment_cancelled;
    _URL_TIERUPGRADE_PAYMENT_REDIRECT = config.url.self.tierupgrade_payment_redirect;
    _URL_TIERUPGRADE_PAYMENT_FAILED = config.url.self.tierupgrade_payment_failed;
    _URL_TIERUPGRADE_PAYMENT_SUCCESS = config.url.self.tierupgrade_payment_success;
    _URL_TIERUPGRADE_PAYMENT_CANCELLED = config.url.self.tierupgrade_payment_cancelled;
    _URL_TRANSFER_PAYMENT_REDIRECT = config.url.self.transfer_payment_redirect;
    _URL_TRANSFER_PAYMENT_FAILED = config.url.self.transfer_payment_failed;
    _URL_TRANSFER_PAYMENT_SUCCESS = config.url.self.transfer_payment_success;
    _URL_TRANSFER_PAYMENT_CANCELLED = config.url.self.transfer_payment_cancelled;
    _URL_CALLBACK = config.url.self.callback_url;
    _URL_REFFERAL = config.url.self.refferal;
    _URL_FB_REFERRAL = config.url.self.fb_referral;
    _URL_TWITTER_REFERRAL = config.url.self.twitter_referral;
    _URL_PAYMENT_EXTEND_RENEW_REDIRECT = config.url.self.extend_renew_redirect;
    _URL_PAYMENT_EXTEND_RENEW_FAILED = config.url.self.extend_renew_payment_failed;
    _URL_PAYMENT_EXTEND_RENEW_SUCCESS = config.url.self.extend_renew_payment_success;
    _URL_PAYMENT_EXTEND_RENEW_CANCELLED = config.url.self.extend_renew_payment_cancelled;
    _URL_GIFTPOINT_PAYMENT_REDIRECT = config.url.self.gift_point_redirect;
    _URL_GIFTPOINT_PAYMENT_FAILED = config.url.self.gift_point_payment_failed;
    _URL_GIFTPOINT_PAYMENT_SUCCESS = config.url.self.gift_point_payment_success;
    _URL_GIFTPOINT_PAYMENT_CANCELLED = config.url.self.gift_point_payment_cancelled;

    _URL_CONFIGURATION_SERVICE = config.url.business_config_service.config_url;

    _URL_AUTH = config.url.auth_service.auth;
    _URL_LOGOUT = config.url.auth_service.logout;
    _URL_LOGIN_DETAILS = config.url.auth_service.fetch_login_details;
    _URL_SOCIAL_LOGIN = config.url.auth_service.social_login_url;
    _URL_SOCIAL_LOGGED_USER = config.url.auth_service.social_logged_user;
    _URL_FETCH_REFRESH_TOKEN = config.url.auth_service.fetch_refresh_token;
    _URL_SOCIAL_LOGIN_FACEBOOK = config.url.auth_service.social_login_url_facebook;
    _URL_SOCIAL_LOGIN_GOOGLE = config.url.auth_service.social_login_url_google;

    _URL_MASTER_RETRIEVE = config.url.master_service.master_retrieve;
    _URL_ADDITIONAL_DETAILS = config.url.master_service.additional_details;

    _URL_ENROLMENT = config.url.member_service.enrolment;
    _URL_FAMILY_ENROLMENT = config.url.member_service.family_enrolment;
    _URL_ENROLMENT_CORPORATE = config.url.member_service.enrolment_corporate;

    _URL_CHECK_MEMBERSHIP_NUMBER = config.url.member_service.membership_number_check;
    _URL_PREAFFILIATED_ENROLMENT = config.url.member_service.preaffiliated_enrolment;

    _URL_CHANGE_PIN_DETAILS = config.url.member_service.pin_change;
    _URL_USER_ACTIVATION = config.url.member_service.useractivation;
    _URL_ADD_TRAVELLER = config.url.member_service.add_traveller;
    _URL_UPDATE_NOMINEE = config.url.member_service.update_nominee;
    _URL_DELETE_NOMINEE = config.url.member_service.delete_nominee;
    _URL_FETCH_QRCODE_IMAGE = config.url.member_service.qrcode_image;
    _URL_ADD_FAMILY_MEMBER = config.url.member_service.add_family_member;
    _URL_FORGOT_PSW_LINK = config.url.member_service.forgot_psw_link;
    _URL_CHANGE_PSW_DETAILS = config.url.member_service.change_psw_link;
    _URL_ACCOUNT_SUMMARY = config.url.member_service.account_summary;
    _URL_DELETE_TRAVELLER = config.url.member_service.delete_traveller;
    _URL_UPDATE_TRAVELLER = config.url.member_service.update_traveller;
    _URL_ADD_NOMINEE = config.url.member_service.add_nominee;
    _URL_RETRIEVE_NOMINEES = config.url.member_service.retrieve_nominees;
    _URL_PREFERENCES_UPDATE = config.url.member_service.update_preference;
    _URL_RETRIEVE_CUSTOMER_PROFILE = config.url.member_service.retrieve_customer_profile;
    _URL_ADD_PROFILE_PICTURE = config.url.member_service.add_profile_picture;
    _URL_FETCH_FAMILY_MEMBERS = config.url.member_service.fetch_family_members;
    _URL_UPDATE_PROFILE_DETAILS = config.url.member_service.update_profile_details;
    _URL_FETCH_PROFILE_DETAILS = config.url.member_service.fetch_profile_details;
    _URL_RETRIEVE_TRAVELLERS = config.url.member_service.retrieve_travellers;
    _URL_RESET_PSW_DETAILS = config.url.member_service.reset_password;
    _URL_FETCH_PROFILE_IMAGE = config.url.member_service.fetch_profile_image;
    _URL_UPDATE_CUSTOMER_PROFILE = config.url.member_service.update_customer_profile;
    _URL_RETRIEVE_ACCOUNT_USERS = config.url.member_service.retrieve_account_users;
    _URL_UPDATE_SECURITY_QUESTIONS = config.url.member_service.update_security_questions;
    _URL_CREATE_SECURITY_TOKEN = config.url.member_service.create_security_token;
    _URL_VALIDATE_SECURITY_TOKEN = config.url.member_service.validate_security_token;
    _URL_VALIDATE_MEMBER_DETAIL = config.url.member_service.validate_member_detail;
    _URL_UPDATE_ACCOUNT_USER_PRIVILEGE = config.url.member_service.update_account_user_privilege;
    _URL_ADD_COMPANION = config.url.member_service.add_companion;
    _URL_REFERRAL = config.url.member_service.referral;
    _URL_ADD_DELETE_NOMINEE = config.url.member_service.add_delete_nominee;
    _URL_ACCOUNT_USER_ACTIVATION = config.url.member_service.account_user_activation;
    _URL_EMAIL_ACTIVATION = config.url.member_service.activate_email;
    _URL_CHANGE_EMAIL = config.url.member_service.change_email;



    _URL_BUY_POINT = config.url.transaction_service.buy_point;
    _URL_TIER_UPGRADE = config.url.transaction_service.tier_upgrade;
    _URL_SEARCH_MEMBER = config.url.transaction_service.search_member;
    _URL_GIFT_MILES = config.url.transaction_service.gift_miles;
    _URL_ACCEPT_PAYMENT = config.url.transaction_service.accept_payment;
    _URL_TRANSFER_POINT = config.url.transaction_service.transfer_point;
    _URL_REQUEST_CLAIM = config.url.transaction_service.request_claim;
    _URL_MIELAGE_CALCULATOR = config.url.transaction_service.mileage_calculator;
    _URL_TRANSACTION_LOGOUT = config.url.transaction_service.transaction_logout;
    _URL_TRANSACTION_AUTHENTICATE = config.url.transaction_service.transaction_authenticate;
    _URL_MIELAGE_CALCULATOR_EARN = config.url.transaction_service.mileage_calculator_earn;
    _URL_MIELAGE_CALCULATOR_BURN = config.url.transaction_service.mileage_calculator_burn;
    _URL_RETRIEVE_RETRO_CLAIM = config.url.transaction_service.fetch_claim_summary
    _URL_RETRIEVE_QUOTE = config.url.transaction_service.fetch_retrieve_quote
    _URL_RENEW_MILES_PAYMENT = config.url.transaction_service.renew_miles_payment
    _URL_GIFT_POINTS = config.url.transaction_service.gift_point;


    _URL_CONVERT_POINTS = config.url.summary_service.convertpoints;
    _URL_FETCH_FLIGHTS = config.url.summary_service.fetch_flights;
    _URL_TRANSACTION_SUMMARY = config.url.summary_service.transaction_summary;
    _URL_FEED_BACK_CREATION = config.url.summary_service.fetch_feedback_details;
    _URL_RETRIEVE_ACTIVITY_DETAIL = config.url.summary_service.retrieve_activity_detail;
    _URL_VIEW_NOTIFICATIONS = config.url.summary_service.view_notifications;
    _URL_RETRIEVE_RENEW_EXPIRY_POINT_DETAILS = config.url.summary_service.retrieve_renew_expiry_point_details;
    _URL_SUBSCRIBE = config.url.summary_service.subscribe;
    _URL_UNSUBSCRIBE = config.url.summary_service.unsubscribe;
    _URL_FETCH_AIRPORT_DETAILS = config.url.summary_service.fetch_airport_details
    _URL_SIMULATE_RULE = config.url.summary_service.simulate_rule

    _URL_CMS = config.url.cms_service.cms;
    _URL_CMS_API = config.url.cms_service.cms_api;
    _URL_CMS_HEADER_FOOTER_CONTENT = config.url.cms_service.header_footer_content;
    _URL_RECOMMENDED_PARTNERS = config.url.cms_service.recommended_partners;
    _URL_RECOMMENDED_BENEFITS = config.url.cms_service.recommended_benefits;
    _URL_VIEW_OFFERPARTNERDETAIL = config.url.cms_service.view_offerpartnerdetail;

    _URL_GULF_AIR = config.url.others.gulfAirUrl;
    _URL_PSS_SEARCH_FLIGHTS = config.url.saber.search_flights;
    _URL_PSS_MODIFY_SEARCH = config.url.saber.modify_search;
    _URL_PSS_LIST_FLIGHTS = config.url.saber.list_flights;

    _URL_I18N_MULTI_LANG = config.i18n.url;
    _WHITELIST_LANGUAGES = config.i18n.languages;
    _NAMESPACE = config.i18n.namespace;
    _DEFAULT_LANGUAGE = config.i18n.default_lng;
    _DEFAULT_NAMESPACE = config.i18n.default_ns
    _IMAGE_BASEURL = config.imageBaseUrl
    _COMPANY_CODE = config.companyCode
    _PROGRAM_CODE = config.programCode
    if(config.chatbot) {
        _CHATBOT_CONFIG = config.chatbot
        window.localStorage.setItem("chatbot", JSON.stringify(config.chatbot))
    }

    /** Resetting i18n config from new config */
    import('../i18n/i18n')
        .then(_i18n => {
            _i18n.reloadConfig();
        })
        .catch(error => {
            console.error("Error while importing the relaod i18n config function: ", error)
        })
}
window.localStorage.setItem("chatbot", JSON.stringify(config.chatbot))
