import { SET_RESET_PASSWORD } from './actions';
const initialState = {};

export default function setResetPswData(state, action) {
    const { type, payload } = action;
    if(!state) state = initialState
    if (type === SET_RESET_PASSWORD) {
        return {
            resetPswInfo: payload
        }
    } else {
        return state;
    }
}