import { BROWSER_STORAGE_KEY_MEMBERSHIP_NO } from '../../common/utils/storage.utils';

export const MEMBERSHIP_NUMBER = BROWSER_STORAGE_KEY_MEMBERSHIP_NO
export const MPN = "MPN"
export const AMP = "&"
export const FIELD_INVALID = "is-invalid"
export const DEFAULT_MEMBER_REFERENCE_TYPE = "M"
export const ACTIVATION_TYPE_SINGLE_STEP = "singleStep"
export const ACTIVATION_TYPE_TWO_STEP = "twoStep"

//document-id
export const ID_SUBMIT_BUTTON = "id-submit-button"

//layouts
export const LOGIN_INFO = 'loginInfo'
export const PERSONAL_INFO = 'personalInfo'
export const CONTACT_INFO = 'contactInfo'
export const COMPANY_INFO = 'companyInfo'
export const AGREE_TERMS = 'agreeTerms'
export const PREFERRED_COMMUNICATION = 'preferredCommunication'


export const TOKEN = "token"
export const FACEBOOK = "facebook"
export const GOOGLE = "google"
export const TRUE = "true"
export const MEMBER_ACCOUNT = "memberAccount"
export const MEMBER_REFERRAL_DETAIL = "memberReferralDetail"
export const REFERENCE_MEMBERSHIP_NUMBER ="referenceMembershipNumber"
export const REFERENCE_TYPE = "referenceType"
export const DYNAMIC_ATTRIBUTES = "dynamicAttributes"
export const ATTRIBUTE_VALUE = "attributeValue"
export const ACCOUNT_STATUS = "accountStatus"
export const ENROLMENT_SOURCE = "enrolmentSource"
export const ENROLMENT_DATE = "enrolmentDate"
export const OBJECT = "object"
export const MEMBER_PROFILE = "memberProfile"
export const MEMBERSHIP_TYPE = "membershipType"
export const INDIVIDUAL_INFO = "individualInfo"
export const MEMBER_CONTACT_INFOS = "memberContactInfos"
export const ADDRESS_TYPE = "addressType"
export const DATE_OF_BIRTH = "dateOfBirth"
export const GET_DATA_FROM_URL = "getDataFromUrl"