import { doPost } from '../../common/utils/api'
import { _URL_ADDITIONAL_DETAILS, _URL_PREFERENCES_UPDATE } from '../../common/config/config'
import { SET_MASTER_DATA, ERROR } from "../../common/middleware/redux/commonAction";
import {
    getItemFromBrowserStorage,
    BROWSER_STORAGE_KEY_COMPANY_CODE, 
    BROWSER_STORAGE_KEY_PROGRAM_CODE
} from '../../common/utils/storage.utils';
import { getApiErrorMessage } from '../../common/utils';
import { ACTION_ADDITIONAL_DETAIL_MEMBERPREFERENCE } from './Constants';
import { stopApiLoading, startApiLoading, startButtonSpinner, stopButtonSpinner } from '../../common/components/fieldbank/loader/action';
import { fetchProfileData } from '../updateprofile/actions';

export const ACTION_ADDITIONAL_DETAIL_MEMBERPREFERENCE_FAILURE="ADDITIONAL_DETAIL_MEMBERPREFERENCE_FAILURE" 
export const ACTION_UPDATE_PREFERENCE_SUCCESS = "ACTION_UPDATE_PREFERENCE_SUCCESS"
export const ACTION_UPDATE_PREFERENCE_FAILURE = "ACTION_UPDATE_PREFERENCE_FAILURE"

/**
 * @description API call for additional details
 * @param {*} type 
 * @author Ajmal Aliyar
 */
export const fetchAdditionalDetails = (type) => {
    let payload = {
        object: {
            companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            type: type
        }
    }

    return async dispatch => {
        dispatch(startApiLoading("fetchAdditionalDetails"))
        await doPost(_URL_ADDITIONAL_DETAILS, payload)
            .then((response) => {
                dispatch(stopApiLoading("fetchAdditionalDetails"))
                dispatch({
                    type: SET_MASTER_DATA,
                    payload: {
                        data: response.data.object.memberPreferences,
                        type: ACTION_ADDITIONAL_DETAIL_MEMBERPREFERENCE
                    }
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchAdditionalDetails"))
                dispatch({
                    type: ACTION_ADDITIONAL_DETAIL_MEMBERPREFERENCE_FAILURE,
                    payload: error
                })
            })
    };
}

/**
 * @description API call to save preferences 
 * @param {*} requestBody 
 * @author Ajmal Aliyar
 */
export const performSavePreference = (requestBody, id) => {
    return dispatch => {
        dispatch(startButtonSpinner(id, "performSavePreference"))
        return doPost(_URL_PREFERENCES_UPDATE, requestBody)
            .then(response => {
                dispatch(stopButtonSpinner(id, "performSavePreference"))
                dispatch(fetchProfileData({}))
                dispatch({
                    type: ACTION_UPDATE_PREFERENCE_SUCCESS,
                    payload: response.data.object
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, "performSavePreference"))
                dispatch({
                    type: ERROR,
                    payload: {error: getApiErrorMessage(error.response.data.error)}
                })
            })
    };
}