import { SET_FOOTERDETAILS } from './actions';

export const initialConfig = {
    footerdetails: {}
}

export function getFooterDetailsReducer( state, action ) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type == SET_FOOTERDETAILS){
        return{
            footerdetails : payload
        }
    } else {
        return state;
    }
}