import React, { Component } from 'react';
import { removeItemFromBrowserStorage, BROWSER_STORAGE_KEY_FIRST_TIME_USER } from '../../utils/storage.utils';
import { ROUTER_CONFIG } from '../../config/routing';

class WelcomeMessage extends Component {

    componentDidMount(){
        const { timer } =  ROUTER_CONFIG.welcomeMessageConfig
        setTimeout(()=>{
            removeItemFromBrowserStorage(BROWSER_STORAGE_KEY_FIRST_TIME_USER)
        }, timer?timer:5000)
    }

    render() {
        const { memno } = this.props
        return (
            <div id="canvasWrapper" className="container welcomeMsg text-center">
                <div className="alert alert-light alert-dismissible fade show" role="alert">
                    <div className="display-4"></div>
                    <div className="h4">You have been enrolled in Loyalty Program.</div>
                    <div className="h6">Your membership number is <strong id='memNo'>{memno}</strong></div>
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <canvas id="mycanvas" className="custom-canvas"></canvas>
                </div>
            </div>
        )
    }
}

export default WelcomeMessage;