import { ACTION_ADD_MEMBER_TRAVELLER, ACTION_RETRIEVE_TRAVEL_COMPANION, ACTION_TRAVEL_COMPANION_DELETE } from "./constants"


/**
 * Reducer for fetching travel companion 
 * @param {*} state 
 * @param {*} action action with type and payload
 * 
 * @author Amrutha
 */
export function retrieveTravelCompanionReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type == ACTION_RETRIEVE_TRAVEL_COMPANION) {
        const companions = payload.object
        return {
            companions
        }
    } else {
        return state;
    }
}
