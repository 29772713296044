import { SET_FORGOT_PSW } from './actions';
const initialState = {};

export default function setForgotPsw(state, action) {
    const { type, payload } = action;
    if(!state) state = initialState
    if(type === SET_FORGOT_PSW) {
            return {
                forgotPassword: payload
            }
        } else {
            return state;
    }
}