import {
    SUBSCRIBE_DATA, SUBSCRIBE_ERROR, UNSUBSCRIBE_DATA, UNSUBSCRIBE_ERROR
} from './actions';

export default function subscribeUnsubscribeReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    switch (type) {
        case SUBSCRIBE_DATA:
            return {
                subscribeData: payload
            }
        case UNSUBSCRIBE_DATA:
            return {
                unsubscribeData: payload
            }
        case SUBSCRIBE_ERROR:
            return {
                subscribeError: payload
            }
        case UNSUBSCRIBE_ERROR:
            return {
                unsubscribeError: payload
            }
        default:
            return state;
    }

}
