import { ENROL_FAMILY_MEMBER } from "./action";

export function enrolFamilyMemberReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === ENROL_FAMILY_MEMBER){
        const member = payload.object
        return {
            member
        }
    }else{
        return state;
    }
}