
import { doPost } from '../../../common/utils/api'
import { _URL_CHANGE_PIN_DETAILS } from '../../../common/config/config'
import { getApiErrorMessage } from '../../../common/utils';
import { ERROR } from '../../../common/middleware/redux/commonAction';
import { startButtonSpinner, stopButtonSpinner } from '../../../common/components/fieldbank/loader/action';

export const SET_PIN_CHANGE = "SET_RESET_PASSWORD";

/**
 * @description Action to call the API which changes the PIN of a user.
 * @param {object} payload Object to be passed as data in Action
 * @author Ajmal Aliyar
 * */

export const changePinAction = (payload, id) => {
  return async dispatch => {
    dispatch(startButtonSpinner(id, "changePinAction"))
    await doPost(_URL_CHANGE_PIN_DETAILS, payload).then((res) => {
      dispatch(stopButtonSpinner(id, "changePinAction"))
      if (res.data) {
        dispatch({
          type: SET_PIN_CHANGE,
          payload: res.data
        })
      }
    }).catch((error) => {
      dispatch(stopButtonSpinner(id, "changePinAction"))
      dispatch({
        type: ERROR,
        payload: { error: getApiErrorMessage(error.response.data.error) }
      })
    })
  };
}

