import { isPatternMatch } from "../utils";
import { getItemFromBrowserStorage, BROWSER_STORAGE_KEY_PROGRAM_TYPE } from "../utils/storage.utils";
import { PROGRAM_TYPE_INDIVIDUAL, PROGRAM_TYPE_CORPORATE } from "../utils/Constants";

export const CONTEXT_PAGE = "PAGE"
export const CONTEXT_TAB = "TAB"
export const FULL_ACCESS = "F"
export const LIMITED_ACCESS = "R"

/**
 * Method to chech privileage on the given context.
 * Eg: RESOURCE = {"url": "/profile", "tab": "tab-name"}
 * 
 * @author Rohith
 * @param {Object contains the resource details} resource
 * @param {Object context tells its a page or tab} context 
 * @param {Rules on privileage} privileges 
 */
export const havePrivilege = (resource, userData, context = CONTEXT_PAGE, privileges = []) => {
    const programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)

    if (programType === PROGRAM_TYPE_CORPORATE &&
        (!userData.nomineeDetails ||
            userData.nomineeDetails.length == 0)) {
        return { canDisplay: false }
    }
    if (context === CONTEXT_PAGE) {
        return havePrivilegeToAccesPage(resource, privileges)
    } else if (context === CONTEXT_TAB) {
        return havePrivilegeToAccesTab(resource, privileges)
    } else {
        console.warn("invalid context...!!!")
    }
    return undefined
}

/**
 * Local method to check the page has access or not
 * @author Rohith
 * @param {*} resource 
 * @param {*} privileges 
 */
const havePrivilegeToAccesPage = (resource, masterPrivileges) => {
    const privilegeData = {
        canDisplay: true,
        permission: FULL_ACCESS
    };
    if (masterPrivileges) {
        masterPrivileges.forEach(privileges => {
            if (privileges && privileges.length > 0) {
                privileges.forEach(privilege => {
                    if (Object.keys(privilege).length > 0 &&
                        isPatternMatch(privilege.url, resource.url)) {
                        if (privilege.permission && privilege.permission.length > 0) {
                            privilegeData["canDisplay"] = true
                            if (privilege.permission.includes(FULL_ACCESS)) {
                                privilegeData["permission"] = FULL_ACCESS
                            } else if (privilege.permission.includes(LIMITED_ACCESS)) {
                                privilegeData["permission"] = LIMITED_ACCESS
                            } else {
                                privilegeData["canDisplay"] = false
                            }
                        }
                    }
                })
            }
        });
    }

    return privilegeData
}

/**
 * Local method to check the tab under a given page has access or not
 * @author Rohith
 * @param {*} resource 
 * @param {*} privileges 
 */
const havePrivilegeToAccesTab = (resource, masterPrivileges) => {
    const privilegeData = {
        canDisplay: true,
        permission: FULL_ACCESS
    };
    if (masterPrivileges) {
        masterPrivileges.forEach(privileges => {
            if (privileges && privileges.length > 0) {
                privileges.forEach(privilege => {
                    if (Object.keys(privilege).length > 0 &&
                        isPatternMatch(privilege.url, resource.url)) {
                        if (privilege.tab && privilege.tab.length > 0) {
                            privilege.tab.forEach(tab => {
                                if (isPatternMatch(tab.name, resource.tab)) {
                                    privilegeData["canDisplay"] = true
                                    if (tab.permission.includes(FULL_ACCESS)) {
                                        privilegeData["permission"] = FULL_ACCESS
                                    } else if (tab.permission.includes(LIMITED_ACCESS)) {
                                        privilegeData["permission"] = LIMITED_ACCESS
                                    } else {
                                        privilegeData["canDisplay"] = false
                                    }
                                }
                            })
                        }
                    }
                });
            }
        })
    }
    return privilegeData
}