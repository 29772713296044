
import { doPost } from '../../common/utils/api'
import { _URL_FEED_BACK_CREATION } from '../../common/config/config'
import { stopButtonSpinner, startButtonSpinner } from '../../common/components/fieldbank/loader/action';


// Action Type
export const SET_FEED_BACK = "SET_FEED_BACK";
/**
 * forgot password
 * @param {*} payload 
 */
export const feedbackDetails = (payload, id) => {
  return async dispatch => {
    dispatch(startButtonSpinner(id, "feedbackDetails"))
    await doPost(_URL_FEED_BACK_CREATION, payload).then((res) => {
      dispatch(stopButtonSpinner(id, "feedbackDetails"))
      if(res.data)
      {
        dispatch({
          type: SET_FEED_BACK,
          payload: res.data
        })
      }
      }).catch((error) => {
        dispatch(stopButtonSpinner(id, "feedbackDetails"))
        dispatch({
          type: SET_FEED_BACK,
          payload: error.response.data
        })
    })
  };
}
