import React, {Component} from 'react'

const MfaContext = React.createContext()
export const MfaConsumer = MfaContext.Consumer;

/**
 * @name MfaProvider The provider component.
 * @description Initalize the provider value and update them based on user actions.
 * @author Somdas M
 */
export class MfaProvider extends Component{
    constructor(props){
        super(props)
        this.state = {
            isOpen: false,
            callback: ()=>{},
            params: [],
            dispatch: ()=>{}
        }

        this.setMfaMethod = this.setMfaMethod.bind(this)
        this.openMfaTab = this.openMfaTab.bind(this)
        this.closeMfaTab = this.closeMfaTab.bind(this)
        this.invokeCallback = this.invokeCallback.bind(this)

    }

    /**
     * Function to open the MFA screen
     * @author Somdas M
     */
    openMfaTab(){
        this.setState({
            isOpen: true
        })
    }

    /**
     * Function to close the MFA screen
     * @author Somdas M
     */
    closeMfaTab(){
        this.setState({
            isOpen: false
        })
    }

    /**
     * Function stores the paused/hold api call 
     * @author Somdas M
     * @param {} callback - The method that needs to be invoked once MFA is success
     * @param {Array} params - The list of parameters for the callback method
     * @param {} dispatch - The disptach function
     */
    setMfaMethod(callback, params, dispatch) {
        this.setState({
            callback,
            params,
            dispatch
        })
    }

    /**
     * Function to invoke the callback method with required params
     * @author Somdas M
     */
    async invokeCallback(){
        const { params } = this.state
        params[3] = { "mfaflag": "true"}
        await this.state.dispatch(this.state.callback.apply({},this.state.params))
    }

    render(){
        const { isOpen, callback, params } = this.state
        const { openMfaTab, closeMfaTab, setMfaMethod, invokeCallback  } = this;
        return(
            <MfaContext.Provider value={{isOpen, callback, params, openMfaTab, closeMfaTab, setMfaMethod, invokeCallback}}>
                {this.props.children}
            </MfaContext.Provider>
        )
    }
}

export default MfaContext;