
import { doPost } from '../../common/utils/api'
import { _URL_CONVERT_POINTS } from '../../common/config/config'
import { setError } from '../../common/middleware/redux/commonAction';
import { getApiErrorMessage } from '../../common/utils';
import { startButtonSpinner, stopButtonSpinner } from '../../common/components/fieldbank/loader/action';


export const SET_POINTS = "SET_POINTS";

/**
 * Creates Action of type <i>_URL_CONVERT_POINTS</i> with given data
 *
 * @function convertPoints
 * @param {object} payload Object to be passed as data in Action
 * @returns Action of type <i>_URL_CONVERT_POINTS</i>
 *
 */
export const convertPoints = (payload, id) => {
  return async dispatch => {
    dispatch(startButtonSpinner(id, "convertPoints"))
    await doPost(_URL_CONVERT_POINTS, payload).then((res) => {
      dispatch(stopButtonSpinner(id, "convertPoints"))
      if(res.data)
      {
        dispatch({
          type: SET_POINTS,
          payload: res.data
      });
      }
      }).catch((error) => {
        dispatch(stopButtonSpinner(id, "convertPoints"))
        dispatch(setError(getApiErrorMessage(error)))
    })
  };
}
