
import {
    startApiLoading,

    startButtonSpinner, stopApiLoading,

    stopButtonSpinner
} from '../../common/components/fieldbank/loader/action';
import {
    _URL_FETCH_PROFILE_DETAILS, _URL_UPDATE_PROFILE_DETAILS
} from '../../common/config/config';
import {
    ERROR,
    fetchAccountSummary,
    fetchProfileData as fetchProfile
} from '../../common/middleware/redux/commonAction';
import { getApiErrorMessage, getCurrentPageUri } from '../../common/utils';
import { doPost } from '../../common/utils/api';
import { PROGRAM_TYPE_INDIVIDUAL } from '../../common/utils/Constants';
import {
    BROWSER_STORAGE_KEY_COMPANY_CODE, BROWSER_STORAGE_KEY_MEMBERSHIP_NO,

    BROWSER_STORAGE_KEY_PROGRAM_CODE,
    BROWSER_STORAGE_KEY_PROGRAM_TYPE, getItemFromBrowserStorage
} from '../../common/utils/storage.utils';
import { NAVIGATE_MEMBER_DASHBOARD, NAVIGATE_MEMBER_PROFILE, NAVIGATE_PROFILE_COMPLETION } from '../../common/utils/urlConstants';

// Action Type
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_PROFILE_PICTURE = "SET_PROFILE_PICTURE";
export const ERROR_PROFILE_FETCH = "ERROR_PROFILE_FETCH";
export const SET_PROFILE_CONFIG = "SET_PROFILE_CONFIG";

export const fetchProfileData = params => {
    const requestBody = {
        object: {
            companyCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_COMPANY_CODE),
            programCode: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_CODE),
            membershipNumber: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_MEMBERSHIP_NO),
        }
    }
    return dispatch => {
        dispatch(startApiLoading("fetchProfileData"))
        return doPost(_URL_FETCH_PROFILE_DETAILS, requestBody)
            .then(response => {
                dispatch(stopApiLoading("fetchProfileData"))
                const changeKey = (oldKey, newKey, objectParam) => {
                    objectParam[newKey] = objectParam[oldKey];
                    delete objectParam[oldKey];
                }
                const deleteKeys = (keys, objectParam) => {
                    keys.forEach(key => {
                        delete objectParam[key];
                    })
                }
                const object = response.data.object;
                if (getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE) == PROGRAM_TYPE_INDIVIDUAL) {
                    // clean object
                    deleteKeys(['isAccrualValid', 'isRedemptionValid', 'memberArtefactDetail'], object);
                    object.paymentDetail = null;
                    // clean object.memberAccount
                    object.memberAccount.enrolmentDate = String('0' + new Date().toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }).replace(/ /ig, '-')).slice(-11);
                    changeKey('accountStatusCode', 'accountStatus', object.memberAccount);
                    changeKey('accountPeriodType', 'periodType', object.memberAccount);
                    changeKey('accountExpiryinMonths', 'period', object.memberAccount);
                    //Commented this to update the profile %
                    //deleteKeys(['memberPreferences', 'accrualSegment'], object.memberAccount);
                    // clean object.memberAccount.memberProfile
                    deleteKeys(['companyCode', 'membershipNumber', 'enrollmentSource', 'corporateInfo', 'enrollmentSourceCode', 'customerPassword'], object.memberAccount.memberProfile)
                    changeKey('membershipStatusCode', 'membershipStatus', object.memberAccount.memberProfile);
                    object.memberAccount.memberProfile.corporateInfo = null;
                }
                if (object.memberAccount.memberProfile && object.memberAccount.memberProfile.individualInfo) {
                    // update keys in object.memberAccount.memberProfile.individualInfo
                    if (!object.memberAccount.memberProfile.individualInfo.designation) {
                        object.memberAccount.memberProfile.individualInfo.designation = "";
                    }
                    // sort object.memberAccount.memberProfile.indivitualInfo.memberContactInfos
                    // const memberContactInfos = object.memberAccount.memberProfile.individualInfo.memberContactInfos.sort((a,b)=>String(a.addressType).localeCompare(b.addressType));
                    const homeAddress = object.memberAccount.memberProfile.individualInfo.memberContactInfos.find(ob => ob.addressType === 'H') || { addressType: 'H' };
                    const businessAddress = object.memberAccount.memberProfile.individualInfo.memberContactInfos.find(ob => ob.addressType === 'B') || { addressType: 'B' };
                    const memberContactInfos = [homeAddress, businessAddress];
                    deleteKeys(['memberContactInfos'], object.memberAccount.memberProfile.individualInfo)
                    object.memberAccount.memberProfile.individualInfo.memberContactInfos = memberContactInfos;
                }
                // console.log({object});
                dispatch(setProfileData({ object }));
                 if(object.areMandatoryFieldsFilled==false) {
                    window.location = `#${NAVIGATE_PROFILE_COMPLETION}`
                }
            }).catch(error => {
                dispatch(stopApiLoading("fetchProfileData"))
                error = error.response.data.error;
                const payload = getApiErrorMessage(error).filter(ob => ob);
                if (payload.length === 0) {
                    payload.push("Request failed")
                }
                dispatch({
                    type: ERROR,
                    payload: { error: payload }
                })
            })
    }
}

export const setProfileData = data => {
    return ({
        type: SET_PROFILE_DATA,
        payload: data
    })
}

export const updateProfileDetails = (profileData, id) => {
    return dispatch => {
        dispatch(startButtonSpinner(id, "updateProfileDetails"))
        return doPost(_URL_UPDATE_PROFILE_DETAILS, profileData)
            .then(response => {
                dispatch(stopButtonSpinner(id, "updateProfileDetails"))
                const programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
                if (programType == PROGRAM_TYPE_INDIVIDUAL) {
                    dispatch(fetchAccountSummary())
                }
                dispatch(fetchProfileData())
                dispatch(fetchProfile())
                dispatch({
                    type: ERROR,
                    payload: { error: ['profile.successMessage'] }
                })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "updateProfileDetails"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.message) }
                })
            })
    }
}