export const createEarnMilesResponse = (data, pageConfig, responsePayload, currentPointsDetails) =>{

    const {doughnutChart, tableView, roundOff} = pageConfig
    const configuredPointsMap = {doughnutChart: {}, tableView: {}}
    if(doughnutChart && doughnutChart.pointsColumnOrder && doughnutChart.pointsColumnOrder.length) {
        doughnutChart.pointsColumnOrder.forEach(order => { 
            doughnutChart.pointsColumnConfigs[order].forEach(p=> {
                if(configuredPointsMap.doughnutChart[p.flightPointType]) {
                    configuredPointsMap.doughnutChart[p.flightPointType].push(order)
                } else {
                    configuredPointsMap.doughnutChart[p.flightPointType] = [order]
                }
            })
        })
    }
    if(tableView && tableView.pointsColumnOrder && tableView.pointsColumnOrder.length) {
        tableView.pointsColumnOrder.forEach(order => { 
            tableView.pointsColumnConfigs[order].forEach(p=> {
                if(configuredPointsMap.tableView[p.flightPointType]) {
                    configuredPointsMap.tableView[p.flightPointType].push(order)
                } else {
                    configuredPointsMap.tableView[p.flightPointType] = [order]
                }
            })
        })
    }
    data.flightAttributePointDetails.map(flightAttributePointDetail => {
        flightAttributePointDetail.pointCreditDetails.map(pointCreditDetail => {
            const {doughnutChart, tableView} = configuredPointsMap
            if(doughnutChart) {
                const pointsConfigForAdding = doughnutChart[pointCreditDetail.pointType]
                if (pointsConfigForAdding) {
                    pointsConfigForAdding.forEach(pc => {
                        addPoints(responsePayload.processed_data.chart, [flightAttributePointDetail.cabinClass, pc], pointCreditDetail.points, currentPointsDetails["doughnutChart"][pc], roundOff)
                    })
                }
            }
            if(tableView) {
                const pointsConfigForAdding = tableView[pointCreditDetail.pointType]
                if (pointsConfigForAdding) {
                    pointsConfigForAdding.forEach(pc => {
                        addPoints(responsePayload.processed_data.table, [flightAttributePointDetail.cabinClass, pc], pointCreditDetail.points, currentPointsDetails["tableView"][pc], roundOff)
                    })
                }
            }
        })
    })
}

const addPoints = (object, path, points, currentPoints, roundOff) => {
    let _object = object
    path.forEach((p, idx)=> {
        if(idx != path.length - 1) {
            if(!_object[p]) {
                _object[p] = {}
            } 
            _object = _object[p]
        } else {
            if(_object[p] != undefined) {
                _object[p]["calculated"] += Math.abs(getAdjustedPoint(points, roundOff))
                _object[p]["balance"] += getAdjustedPoint(points, roundOff)
            } else {
                let balance = currentPoints + points
                balance = getAdjustedPoint(balance, roundOff)
                let current = getAdjustedPoint(currentPoints, roundOff)
                _object[p] = {
                    calculated: Math.abs(getAdjustedPoint(points, roundOff)),
                    current,
                    balance
                }
            }
        }
    })
}

export const getAdjustedPoint = (point, roundOff={}) => {
    if(roundOff && Object.keys(roundOff).length){
        const { direction, places } = roundOff
        switch(direction){
            case "up": 
                return Math.round(point)
            case "down": 
                return parseInt(point)
            default: 
                return point ? 
                    parseFloat(point) == parseInt(point) ?
                        parseInt(point)
                        : places ?
                            parseFloat(point).toFixed(places)
                            : parseFloat(point)
                    : 0
        }
    }
    return point ? (parseFloat(point) == parseInt(point) ? parseInt(point) : parseFloat(point).toFixed(2)) : 0
}

export const createUseMilesResponse = (data, pageConfig, cabinClass, responsePayload, currentPointsDetails) =>{
    if(data.reward){
        const {doughnutChart, tableView} = pageConfig
        responsePayload.rowData.api = data.reward  //FIXME: If multiple rewards are returned from burn api
        data.reward.forEach( r => {
            //We are receving only one point type for Use Miles case, so in the proccessed data it will be getting added every time
            //Once we get for multiple points types we should change the code here.
            const points = r.rewardPricingDetail.rewardPoints * -1
            if(doughnutChart && doughnutChart.pointsColumnOrder && doughnutChart.pointsColumnOrder.length) {
                doughnutChart.pointsColumnOrder.forEach(order => { 
                    addPoints(responsePayload.processed_data.chart, [cabinClass, order], points, currentPointsDetails["doughnutChart"][order])
                })
            }
            if(tableView && tableView.pointsColumnOrder && tableView.pointsColumnOrder.length) {
                tableView.pointsColumnOrder.forEach(order => { 
                    addPoints(responsePayload.processed_data.table, [cabinClass, order], points, currentPointsDetails["tableView"][order])
                })
            }
        })
    }
}

export const createUpgradeUsingMilesResponse = (data, pageConfig, cabinClass, responsePayload, upgradeTo, currentPointsDetails) => {

    if (data.reward) {
        const { doughnutChart, tableView } = pageConfig
        responsePayload.rowData.api = data.reward  //FIXME: If multiple rewards are returned from burn api
        data.reward.forEach(r => {
            //We are receving only one point type for Use Miles case, so in the proccessed data it will be getting added every time
            //Once we get for multiple points types we should change the code here.
            const points = r.rewardPricingDetail.rewardPoints * -1
            if (doughnutChart && doughnutChart.pointsColumnOrder && doughnutChart.pointsColumnOrder.length) {
                doughnutChart.pointsColumnOrder.forEach(order => {
                    addPoints(responsePayload.processed_data.chart, [upgradeTo, order], points, currentPointsDetails["doughnutChart"][order])
                })
            }
            if (tableView && tableView.pointsColumnOrder && tableView.pointsColumnOrder.length) {
                tableView.pointsColumnOrder.forEach(order => {
                    addPoints(responsePayload.processed_data.table, [upgradeTo, order], points, currentPointsDetails["tableView"][order])
                })
            }
        })
    }
}
