import React, { Component } from 'react'
import { getItemFromBrowserStorage, BROWSER_STORAGE_KEY_TOKEN } from '../../components/common/utils/storage.utils';
import { isEmptyOrSpaces } from '../../components/common/utils';
import { NAVIGATE_MEMBER_LOGIN } from '../../components/common/utils/urlConstants';
import _503 from '../../components/ui/errors/503';

class _503Page extends Component {
    componentDidMount(){
        const props = {
            ...this.props,
            includeHeader : false
        }
        this.props.setErrorPageInfo(props)
    }
    render() {
        return<_503 />
    }
}


export default _503Page;