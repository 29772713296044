import {
    REFERRAL_DATA,
    REFERRAL_ERROR,
} from './Constants';

export const referralDataReducer = (state, action) => {
    if (!state) state = {}
    const { type, payload } = action
    switch (type) {
        case REFERRAL_DATA:
            return {
                referralData: payload
            }
        case REFERRAL_ERROR:
            return {
                referralDataError: payload
            }               
        default:
            return state;
    }
}
