import { _URL_FAMILY_ENROLMENT, _URL_ADD_DELETE_NOMINEE, _URL_RETRIEVE_NOMINEES } from "../../common/config/config"
import { ERROR, ACTION_ADD_DELETE_NOMINEE, ADD_OR_DELETE } from "../../common/middleware/redux/commonAction"
import { startButtonSpinner, stopButtonSpinner, stopApiLoading, startApiLoading } from "../../common/components/fieldbank/loader/action"
import { getApiErrorMessage } from "../../common/utils"
import { doPost } from "../../common/utils/api"


export const ENROL_FAMILY_MEMBER = "ENROL_FAMILY_MEMBER"
export const FETCH_FAMILY_LIST = "FETCH_FAMILY_LIST"

export const enrolFamilyMember = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "enrolFamilyMember"))
        dispatch({
            type: ENROL_FAMILY_MEMBER,
            payload: {}
        })
        await doPost(_URL_FAMILY_ENROLMENT, payload)
            .then(response => {
                dispatch(stopButtonSpinner(id, "enrolFamilyMember"))
                dispatch({
                    type: ENROL_FAMILY_MEMBER,
                    payload: response.data
                })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "enrolFamilyMember"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

export const addOrDeleteNominee = (payload, id) => {
    return async dispatch => {
        dispatch({
            type: ACTION_ADD_DELETE_NOMINEE,
            payload: {}
        })
        dispatch(startButtonSpinner(id, ADD_OR_DELETE))
        await doPost(_URL_ADD_DELETE_NOMINEE, payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id, ADD_OR_DELETE))
                dispatch({
                    type: ACTION_ADD_DELETE_NOMINEE,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopButtonSpinner(id, ADD_OR_DELETE))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            })
    }
}

export const fetchFamilyList = (payload) => {
    return async dispatch => {
        dispatch({
            type: FETCH_FAMILY_LIST,
            payload: {}
        })
        dispatch(startApiLoading("fetchFamilyList"))
        return await doPost(_URL_RETRIEVE_NOMINEES, payload)
            .then(response => {
                dispatch(stopApiLoading("fetchFamilyList"))
                dispatch({
                    type: FETCH_FAMILY_LIST,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("fetchFamilyList"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            })
    };

}