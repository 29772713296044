import {
    _URL_SEARCH_MEMBER,
    _URL_TRANSACTION_AUTHENTICATE,
    _URL_TRANSACTION_LOGOUT,
    _URL_TRANSFER_POINT,
    _URL_ACCEPT_PAYMENT,
    _URL_TRANSFER_PAYMENT_CANCELLED,
    _URL_TRANSFER_PAYMENT_SUCCESS,
    _URL_TRANSFER_PAYMENT_FAILED,
    _URL_TRANSFER_PAYMENT_REDIRECT
} from '../../common/config/config';
import { doPost } from '../../common/utils/api';
import {
    getApiErrorMessage
} from '../../common/utils'
import {
    fetchAccountSummary, getFamilyMembers
} from '../../common/middleware/redux/commonAction'

export const ACCOUNT_SUMMARY_FETCH_IN_PROGRESS = "ACCOUNT_SUMMARY_FETCH_IN_PROGRESS";
export const SET_ACCOUNT_SUMMARY = "SET_ACCOUNT_SUMMARY";
export const ACCOUNT_SUMMARY_FETCH_ERROR = "ACCOUNT_SUMMARY_FETCH_ERROR";
export const SET_SEARCH_MEMBER = "SET_SEARCH_MEMBER";
// export const SEARCH_MEMBER_FETCH_ERROR = "SEARCH_MEMBER_FETCH_ERROR";
export const HIDE_TRANSFER_MILES_MESSAGE = "HIDE_TRANSFER_MILES_MESSAGE";
export const SET_TRANSFER_MILES_MESSAGE = "SET_TRANSFER_MILES_MESSAGE";
export const CLEAR_SEARCH_MEMBER = "CLEAR_SEARCH_MEMBER";
// export const SET_TRANSFERMILES_CONFIG = 'SET_TRANSFERMILES_CONFIG';

const SUCCESS_MESSAGE = "Transfer points successfully completed";
const ERROR_MESSAGE = "Error occured while transfering points";
import { ERROR } from '../../common/middleware/redux/commonAction';
import {
    getItemFromBrowserStorage,
    setItemToBrowserStorage,
    BROWSER_STORAGE_KEY_CURRENCY_CODE,
    BROWSER_STORAGE_KEY_RESPOMSE,
    BROWSER_STORAGE_KEY_PARAMS,
    BROWSER_STORAGE_TYPE_SESSION,
    BROWSER_STORAGE_KEY_BASEPOINTS
} from '../../common/utils/storage.utils';
import { stopButtonSpinner, startButtonSpinner } from '../../common/components/fieldbank/loader/action';

// import MfaContext from '../../../context/MfaContext'
import { MFA_REQUIRED } from '../../common/components/mfa/Constants';

/**
 * Search member api call
 * @author Alan Kuriakose
 * @param {object} payload - request object
 * @param {object} id - id
 */
export const searchMember = (payload, id) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, "searchMember"))
        await doPost(_URL_SEARCH_MEMBER, payload)
            .then(response => {
                dispatch(stopButtonSpinner(id, "searchMember"))
                dispatch({
                    type: SET_SEARCH_MEMBER,
                    payload: response.data
                })
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "searchMember"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

/**
 * Function to authenticate user for making payment
 * @author Alan Kuriakose
 * @param {object} params - parameters for api call
 */
export const makePayment = (params, id, config, context) => {
    // Authenticate user
    const { companyCode, programCode, membershipNumber, pin, isProtectedApi } = params;
    const authenticateRequestBody = {
        object: {
            companyCode,
            programCode,
            membershipNumber,
            pin
        }
    }
    return async dispatch => {
        if (!isProtectedApi) {
            return dispatch(transferPoints({ ...params }, id, config, context))
        }
        dispatch(startButtonSpinner(id, "makePayment"))
        await doPost(_URL_TRANSACTION_AUTHENTICATE, authenticateRequestBody)
            .then(response => {
                dispatch(stopButtonSpinner(id, "makePayment"))
                const transactionId = response.data.object.transactionID;
                dispatch(transferPoints({ ...params, transactionId }, id, config));
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "makePayment"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

/**
 * Transfer points api call
 * @author Alan Kuriakose
 * @param {object} params - parameters for api call
 */
export const transferPoints = (params, id, config, context, headers = {}) => {
    const saveParams = { ...params }
    const callBackParams = [saveParams, id, context, headers]
    const { companyCode, programCode, transactionId, membershipNumber, quoteReferenceNumber, loyaltyMembershipNumberOfRecipient, pointsToTransfer, pointType, order_amount, total_price, isPaymentRequired, acceptPaymentRequired, isBonus, transactionType, pos_city, pos_country, show_miles_only, adhocAttributes } = params;
    const transferPointsRequestBody = {
        object: {
            companyCode,
            programCode,
            membershipNumber,
            quoteReferenceNumber,
            loyaltyMembershipNumberOfRecipient,
            transactionId,
            transactionType,
            points: [
                {
                    pointType,
                    points: parseInt(pointsToTransfer),
                    isBonus
                }
            ],
            adhocAttributes
        }
    }
    if (isPaymentRequired) {
        transferPointsRequestBody.object["paymentGateway"] = {
            cancel_url: _URL_TRANSFER_PAYMENT_CANCELLED,
            success_url: _URL_TRANSFER_PAYMENT_SUCCESS,
            failure_url: _URL_TRANSFER_PAYMENT_FAILED,
            redirect_url: _URL_TRANSFER_PAYMENT_REDIRECT,
            terms_and_conditions: config && config.termsAndConditions ? config.termsAndConditions : "",
            point_of_sale: {
                booking_channel: config &&
                    config.bookingChannel &&
                    config.bookingChannel.web ? config.bookingChannel.web : "",
                pos_city,
                pos_country
            },
            order_details: {
                order_info: config && config.description ? config.description : "",
                order_type: "",
                items: [
                    {
                        total_price,
                        quantity: 1,
                        airline_data: {
                            origin_destination: [
                                {
                                    segments: [
                                        {}
                                    ]
                                }
                            ]
                        }
                    }
                ]
            }
        }
        if (show_miles_only == "N") {
            transferPointsRequestBody.object["paymentGateway"]["purchase_totals"] = {
                show_miles_only,
                order_amount,
                order_currency: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CURRENCY_CODE)
            }
        } else if (show_miles_only == "Y") {
            transferPointsRequestBody.object["paymentGateway"]["purchase_totals"] = {
                show_miles_only,
                order_amount,
                order_currency: getItemFromBrowserStorage(BROWSER_STORAGE_KEY_CURRENCY_CODE),
                miles: {
                    redeemed_miles: 0
                }
            }
        }
    }

    return async dispatch => {
        dispatch(startButtonSpinner(id, "transferPoints"))
        await doPost(_URL_TRANSFER_POINT, transferPointsRequestBody)
            .then(response => {
                dispatch(stopButtonSpinner(id, "transferPoints"))
                params.transactionId = response.data.object.transactionId;
                const { memberActivityStatus } = response.data.object;
                params = { ...params, ...memberActivityStatus }
                setItemToBrowserStorage(BROWSER_STORAGE_KEY_RESPOMSE, JSON.stringify(response.data))
                const { redirectURL } = response.data.object;
                setItemToBrowserStorage(
                    BROWSER_STORAGE_KEY_PARAMS,
                    JSON.stringify(params),
                    BROWSER_STORAGE_TYPE_SESSION
                )
                if (isPaymentRequired) {
                    var res = redirectURL.split("sessionKey=");
                    window.location.href = res[0] + "sessionKey=" + encodeURIComponent(res[1]);
                } else if(acceptPaymentRequired){
                    dispatch(acceptPayment(params));
                } else {
                    dispatch(logout(params));
                }
            }).catch(responseError => {
                dispatch(stopButtonSpinner(id, "transferPoints"))
                const { error } = responseError.response.data
                if (error.type && error.type == MFA_REQUIRED) {
                    const { openMfaTab, setMfaMethod } = context
                    setMfaMethod(transferPoints, callBackParams, dispatch)
                    openMfaTab()
                } else {
                    dispatch({
                        type: ERROR,
                        payload: { error: getApiErrorMessage(responseError.response.data.error) }
                    })
                }

            })
    }
}
/**
 * Function to make accept payment api call
 * @author Alan Kuriakose
 * @param {object} params - parameters for api call
 */
export const acceptPayment = (params, id) => {
    const {
        companyCode,
        programCode,
        transactionId,
        membershipNumber,
        activityCode,
        activityName,
        activityNumber,
        activityType,
        paymentGateWayRefNumber,
        order_amount,
        order_currency,
        pointType,
        transactionType
    } = params;

    const acceptPaymentRequestBody = {
        object: {
            companyCode,
            programCode,
            membershipNumber,
            activityType,
            transactionId,
            activityNumber,
            activityCode,
            promotionIdentifier: "",
            paymentRemark: activityName,
            paymentDetail: [
                {
                    pointsCollected: "0",
                    amountCollected: order_amount,
                    pointType,
                    paymentType: '',
                    currencyCode: order_currency,
                    paymentSource: '',
                    paymentGateWayRefNumber: paymentGateWayRefNumber || '',
                    quoteReferenceNumber: ""
                }
            ],
            transactionType
        }
    }
    return async dispatch => {
        dispatch(startButtonSpinner(id, "acceptPayment"))
        await doPost(_URL_ACCEPT_PAYMENT, acceptPaymentRequestBody)
            .then(response => {
                dispatch(stopButtonSpinner(id, "acceptPayment"))
                const responseObject = response.data.object;
                params = { ...params, ...responseObject };
                dispatch(logout(params, id));
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "acceptPayment"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

/**
 * Logout api call
 * @author Alan Kuriakose
 * @param {object} params - parameters for api call
 */
export const logout = (params, id) => {
    const { companyCode, programCode, membershipNumber, transactionId, activityNumber, isProtectedApi } = params;
    if (!isProtectedApi) {
        return dispatch => postLogout(dispatch, params);
    }
    const logoutRequestBody = {
        object: {
            companyCode,
            programCode,
            membershipNumber,
            transactionID: transactionId,
            activityNumber
        }
    }
    return async (dispatch) => {
        dispatch(startButtonSpinner(id, "logout"))
        await doPost(_URL_TRANSACTION_LOGOUT, logoutRequestBody)
            .then(response => {
                dispatch(stopButtonSpinner(id, "logout"))
                postLogout(dispatch, params);
            }).catch(error => {
                dispatch(stopButtonSpinner(id, "logout"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
            })
    }
}

/**
 * This is the function to perform all post logout actions
 * @author Alan Kuriakose
 */
export const postLogout = (dispatch, params) => {
    const { resetStates, isPaymentRequired } = params;

    dispatch(fetchAccountSummary()).then(response => {
        const basePoints = getPoints(response.pointDetails, params.pointType);
        setItemToBrowserStorage(
            BROWSER_STORAGE_KEY_BASEPOINTS,
            basePoints,
            BROWSER_STORAGE_TYPE_SESSION
        )
        dispatch(setTransferMilesMessage(response.data, 'success'));
        dispatch({
            type: 'FETCH_ACCOUNT_SUMMARY',
            payload: {
                object: response
            }
        })
    })

    const {
        companyCode,
        programCode,
        membershipNumber,
    } = params;
    const object = { companyCode, programCode, membershipNumber }
    dispatch(getFamilyMembers({ object }));
    // dispatch(setTransferMilesStep(1));
    if(!isPaymentRequired) resetStates();
}

/**
 * To set message in transfer miles screen
 * @author Alan Kuriakose
 * @param {string} message - message to be set
 * @param {string} type - type of message - success, danger, warning etc.. ( bootstrap class names )
 */
export const setTransferMilesMessage = (message = '', type = '') => {

    const object = {
        type: SET_TRANSFER_MILES_MESSAGE,
        payload: {
            type,
            message
        }
    }

    switch (type) {
        case 'danger':
            object.payload.message = message || ERROR_MESSAGE
            break;
        case 'success':
            object.payload.message = message || SUCCESS_MESSAGE
            break;
    }
    return object;
}

/**
 * To hide transfer miles messages
 * @author Alan Kuriakose
 */
export const hideTransferMilesMessage = () => ({
    type: HIDE_TRANSFER_MILES_MESSAGE
})

/**
 * Function to get points available
 * @author Alan Kuriakose
 * @param {array} arr - array describing points
 * @param {string} type - type of points to retrieve
 */
const getPoints = (arr, type) => {
    const obj = arr.filter(ob => ob.pointType === type)[0];
    return parseInt(obj ? obj.points : 0);
}

export const clearSearchMember = {
    type: CLEAR_SEARCH_MEMBER
}
