import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { store } from './components/common/middleware/store';
import { Provider } from 'react-redux';
import './components/common/i18n/i18n';
import Main from './pages';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './styles/customPrimereact/datatable.scss';
import './styles/customPrimereact/main.scss';
import { MfaProvider } from './context/MfaContext';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <MfaProvider>
          <Main />
        </MfaProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
