export const HOME_PHONE = "HP"
export const HOME_MOBILE = "HM"
export const BUSINESS_PHONE = "BP"
export const HOME = "H"
export const BUSINESS = "B"
export const WHITE_SPACE = " "
export const CHANGE_EMAIL_SUCCESS_RESPONSE = "Success"
export const CHANGE_EMAIL_FAILED_RESPONSE = "Failed"
export const SUCCESS = "success"
export const DANGER = "danger"
export const EMAIL_STATUS_INACTIVE = "I"

export const CHANGE_EMAIL = "CHANGE_EMAIL"
export const ID_SPINNER_CHANGE_EMAIL = "ID_SPINNER_CHANGE_EMAIL"
export const RESET_CHANGE_EMAIL = "RESET_CHANGE_EMAIL"
export const CANCEL_CHANGE_EMAIL_BUTTON = "CANCEL_CHANGE_EMAIL_BUTTON"
export const CHANGE_EMAIL_BUTTON = "CHANGE_EMAIL_BUTTON"


