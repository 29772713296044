import {
    SET_SEARCH_MEMBER,
    SET_TRANSFER_MILES_MESSAGE,
    HIDE_TRANSFER_MILES_MESSAGE,
    CLEAR_SEARCH_MEMBER,
} from './actions';

export const initialState = {
    summary: {},
    error: false,
    fetchInProgress: false
};

export const searchMemberInitialState = {
    memberDetails: {
        firstName: '',
        surname: ''
    },
    fetchError: false
}

export const searchMember = (state, action) => {
    const { type, payload } = action;
    if(!state) state = searchMemberInitialState
    switch(type) {
        case SET_SEARCH_MEMBER:
            return({
                memberDetails: payload.object,
                fetchError: false
            })
        case CLEAR_SEARCH_MEMBER:
            return searchMemberInitialState;
        default:
            return state;
    }
}

const transfermilesmessageInitialState = {
    message: '',
    showMessage: false,
    type: ''
}

export const transferMilesMessage = (state, action) => {
    const { type, payload } = action;
    if(!state) state = transfermilesmessageInitialState
    switch(type) {
        case SET_TRANSFER_MILES_MESSAGE:
            return {
                showMessage: true,
                ...payload

            }
        case HIDE_TRANSFER_MILES_MESSAGE:
            return transfermilesmessageInitialState;
        default:
            return state;
    }
}

