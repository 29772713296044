import {
    TRANSACTION_AUTHENTICATE_FAILED,
    BUY_POINT_FAILED,
    BUY_POINT_ACCEPT_PAYMENT_FAILED,
    TRANSACTION_AUTHENTICATE_LOGOUT_FAILED,
    PAYMENT_INIT_FAILED,
    LOAD_CONFIG_ERROR
} from '../../../ui/buypoints/action';

import {
    FETCH_ACCOUNT_SUMMARY_ERROR,
    ERROR,
    FETCH_ACCOUNT_NOMINEE_FAILURE,
    ACTION_FETCH_DEFAULT_USER_PROFILEDATA_FAILURE
} from './commonAction'
import {
    ENROLMENT_ERROR
} from '../../../ui/enrolment/action'
import {
    ACTION_ADDITIONAL_DETAIL_MEMBERPREFERENCE_FAILURE
} from '../../../ui/preferences/actions'
import {
    ACTION_FETCH_PROFILEDATA_FAILURE
} from './commonAction'
import {
    NEW_FAMILY_MEMBER_SEARCH_ERROR,
    ADD_FAMILY_MEMBER_ERROR,
    ADD_FAMILY_CONFIG_ERROR
} from '../../../ui/addfamilymember/action'
import {
    CLAIM_REQUEST_ERROR
} from '../../../ui/claimsubmit/actions'
import {
    MILEAGE_CALCULATOR_FAILED
} from '../../../ui/mileagecalculator/action';
import {
    RETRIEVE_RETRO_CLAIM_ERROR
} from '../../../ui/claimsummary/actions';
import {
    CONFIG_MY_ACTIVITY_FAILURE,
    RETRIEVE_ACTIVITY_DETAIL_FAILURE,
    MY_ACTIVITY_AUTHENTICATE_TRANSACTION_FAILURE,
    MY_ACTIVITY_LOGOUT_TRANSACTION_FAILURE
} from '../../../ui/myactivity/actions';

import {
    RESET_ERROR
} from './commonAction'
import { ACTION_FETCH_FLIGHTS_FAILURE } from '../../../ui/myflight/actions';



export function commonErrorReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    switch (type) {
        case MILEAGE_CALCULATOR_FAILED:
        case TRANSACTION_AUTHENTICATE_FAILED:
        case BUY_POINT_FAILED:
        case BUY_POINT_ACCEPT_PAYMENT_FAILED:
        case TRANSACTION_AUTHENTICATE_LOGOUT_FAILED:
        case FETCH_ACCOUNT_SUMMARY_ERROR:
        case PAYMENT_INIT_FAILED:
        case ENROLMENT_ERROR:
        case NEW_FAMILY_MEMBER_SEARCH_ERROR:
        case ADD_FAMILY_MEMBER_ERROR:
        case LOAD_CONFIG_ERROR:
        case CLAIM_REQUEST_ERROR:
        case ADD_FAMILY_CONFIG_ERROR:
        case RETRIEVE_RETRO_CLAIM_ERROR:
        case CONFIG_MY_ACTIVITY_FAILURE:
        case RETRIEVE_ACTIVITY_DETAIL_FAILURE:
        case MY_ACTIVITY_AUTHENTICATE_TRANSACTION_FAILURE:
        case MY_ACTIVITY_LOGOUT_TRANSACTION_FAILURE:
        case ACTION_FETCH_PROFILEDATA_FAILURE:
        case ACTION_ADDITIONAL_DETAIL_MEMBERPREFERENCE_FAILURE:
        case ACTION_FETCH_FLIGHTS_FAILURE:
        case FETCH_ACCOUNT_NOMINEE_FAILURE:
        case ACTION_FETCH_DEFAULT_USER_PROFILEDATA_FAILURE:
        case ERROR:
            const { error } = payload
            return {
                error
            }
        case RESET_ERROR:
            return {}
        default:
            return state;
    }
}
