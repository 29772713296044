import { startApiLoading, startButtonSpinner, stopApiLoading, stopButtonSpinner } from "../../common/components/fieldbank/loader/action";
import { _URL_FEED_BACK_CREATION, _URL_MASTER_RETRIEVE } from "../../common/config/config";
import { SET_MASTER_DATA } from "../../common/middleware/redux/commonAction";
import { getApiErrorMessage, isApiResponseAuthorized } from "../../common/utils";
import { doPost } from "../../common/utils/api";
import { MASTER_REARRANGE } from "../../common/utils/Constants";
import { ID_FEEDBACK } from "./Constants";
import { getOnetimeKey, getAttributeCode } from "./utils";

export const SET_FEEDBACK_DYSECTION = "SET_FEEDBACK_DYSECTION"
export const SET_FEED_BACK = "SET_FEED_BACK"

export const submitFeedBack = (payload, id, headers, t) => {
    return async dispatch => {
        dispatch(startButtonSpinner(id, ID_FEEDBACK))
        dispatch({
            type: SET_FEED_BACK,
            payload: {}
        })
        await doPost(_URL_FEED_BACK_CREATION, payload, headers).then((res) => {
            dispatch(stopButtonSpinner(id, ID_FEEDBACK))
            if (res.data) {
                dispatch({
                    type: SET_FEED_BACK,
                    payload: res.data
                })
            }
        }).catch((error) => {
            dispatch(stopButtonSpinner(id, ID_FEEDBACK))
            try {
                dispatch({
                    type: SET_FEED_BACK,
                    payload: { error: getApiErrorMessage(error.response.data) }
                })
            } catch(error) {
                dispatch({
                    type: SET_FEED_BACK,
                    payload: { error: [t('feedBack_v2.messages.errorMessage')] }
                })
            }
        })
    };
}

export const fetchMasterDataForFeedBack = (oneTimeMap, dySectionData, entityCode, sectionIndex, itemIntex, languageCode, masterData, invokedChild = false) => {
    if (entityCode && oneTimeMap && oneTimeMap[entityCode]) {
        return async dispatch => {
            dispatch(startApiLoading(`fetchMasterData[${entityCode}]`))
            dispatch({
                type: SET_MASTER_DATA,
                payload: { data: [], type: entityCode }
            });
            await doPost(_URL_MASTER_RETRIEVE, oneTimeMap[entityCode]).then(response => {
                dispatch(stopApiLoading(`fetchMasterData[${entityCode}]`))
                if (response.data.object && response.data.object.masterRecordList) {
                    let { masterRecordList } = response.data.object
                    if (MASTER_REARRANGE[entityCode]) {
                        const rearrangeConfig = MASTER_REARRANGE[entityCode]
                        if (rearrangeConfig.defaultValue) {
                            let index = -1, defaultObj = {};
                            masterRecordList.some(function (entry, i) {
                                if (entry.CountryCode == rearrangeConfig.defaultValue) {
                                    index = i;
                                    defaultObj = entry;
                                    return true;
                                }
                            });
                            if (index != -1) {
                                masterRecordList.splice(index, 1);
                                masterRecordList.unshift(defaultObj);
                            }
                        }
                    }

                    dispatch({
                        type: SET_MASTER_DATA,
                        payload: { data: masterRecordList, type: entityCode }
                    });
                    dispatch(setDynamicData(oneTimeMap, dySectionData, entityCode, sectionIndex, itemIntex, masterRecordList, languageCode, masterData, invokedChild));

                } else {
                    dispatch({
                        type: SET_MASTER_DATA,
                        payload: { data: [], type: entityCode }
                    });
                }
            }).catch(error => {
                dispatch(stopApiLoading(`fetchMasterData[${entityCode}]`))
                isApiResponseAuthorized(error)
            })
        }
    }
}

export const setDynamicData = (oneTimeMap, dySectionData, entityCode, sectionIndex, itemIntex, data, languageCode, masterData, invokedChild = false) => {
    return dispatch => {
        if (data &&
            dySectionData &&
            sectionIndex < dySectionData.length &&
            dySectionData[sectionIndex]) {
            dySectionData[sectionIndex].data.onetime = entityCode
            dySectionData[sectionIndex].data.renderData = data
            dySectionData[sectionIndex].data.selectedItem = itemIntex
            //dySectionData[sectionIndex].data["selectedValue"] = getAttributeCode(entityCode, dySectionData[sectionIndex].locale, languageCode)
            dySectionData[sectionIndex].data.selectedValue = data && itemIntex < data.length ? data[itemIntex].FieldValue : ""
        }
        dispatch({
            type: SET_FEEDBACK_DYSECTION,
            payload: dySectionData
        });
        callNext(sectionIndex + 1, dySectionData, dySectionData[sectionIndex].data["selectedValue"], languageCode, dispatch, oneTimeMap, masterData);
    }

}

export const callNext = (_sectionIndex, dySectionData, nextEntityCode, languageCode, dispatch, oneTimeMap, masterData) => {
    if (_sectionIndex < dySectionData.length && nextEntityCode) {
        const _field = dySectionData[_sectionIndex]
        const _code = getOnetimeKey(nextEntityCode, _field.locale, languageCode);
        if (masterData[_code]) {
            dispatch(setDynamicData(oneTimeMap, dySectionData, _code, _sectionIndex, 0, masterData[_code], languageCode, masterData, true))
        } else {
            dispatch(fetchMasterDataForFeedBack(oneTimeMap, dySectionData, _code, _sectionIndex, 0, languageCode, masterData, true));
        }
    }
}

