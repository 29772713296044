import { SET_FEED_BACK } from './actions';
const initialState = {};

export default function setFeedBack(state, action) {
    if (!state) state = initialState;
    const { type, payload } = action;
    if (type == SET_FEED_BACK) {
        return {
            feedBackDetails: payload
        }
    }
    else {
        return state;
    }
}