import {
    NEW_FAMILY_MEMBER_SEARCH,
    ADD_FAMILY_MEMBER
} from './action';

export function newFamilyMemberSearchReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === NEW_FAMILY_MEMBER_SEARCH){
        const tierChart = payload.object
        return {
            tierChart
        }
    }else{
        return state;
    }
}

export function addFamilyMemberReducer(state, action) {
    const { type, payload } = action;
    if(!state) state = {}
    if(type === ADD_FAMILY_MEMBER){
        const tierChart = payload.object
        return {
            tierChart
        }
    } else {
        return state;
    }
}

