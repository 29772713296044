import { doGet } from "./api"

/**
 * Method to fetch the css data and load it on the element
 * The returned data is stored on 'lang'
 * @author Somdas M
 * @param {*} url The file location
 * @param {*} element The DOM element
 */
export const fetchCss = async (url, element) => {
    if(url){
        await doGet(url).then(response => {
            addStyleToElement(response.data, element)
            return response.data
        }).catch(err=>{
            return undefined
        })
    } else {
        addStyleToElement("", element)
        return ""
    }
}

/**
 * Method to alter the DOM element and include css
 * @author Somdas M
 * @param {*} value The css value
 * @param {*} element The DOM element
 */
export const addStyleToElement = (value, element) => {
    if (element && element.styleSheet) {   // IE
        element.styleSheet.cssText = value;
    } else if(element){                // the world
        element.innerHTML = value;
    }
}