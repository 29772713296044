import { FETCH_PROFILE_IMAGE } from '../../common/middleware/redux/commonAction';

export function profileImageReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    if (type === FETCH_PROFILE_IMAGE) {
        const profileImage = payload.object
        return {
            profileImage
        }
    } else {
        return state;
    }
}