import { doPost } from "../../common/utils/api"
import { _URL_USER_ACTIVATION } from "../../common/config/config"
import { ERROR } from "../promotions/actions"
import { getApiErrorMessage } from "../../common/utils"
import { startButtonSpinner, stopButtonSpinner } from "../../common/components/fieldbank/loader/action"
import * as API_URL from '../../common/config/config'
export const ACTIVATE = "ACTIVATE"

// export const loadingProgress = (payload) => {
//     return (dispatch) => {
//         dispatch({
//             type: "LOADING_PROGRESS_BAR",
//             payload
//         })
//     }
// }


export const activate = (payload, id,url) => {
    return async dispatch=>{
        dispatch(startButtonSpinner(id, "activate"))
        await doPost(API_URL[url], payload)
            .then((response) => {
                dispatch(stopButtonSpinner(id,"activate"))
                dispatch({
                    type: ACTIVATE,
                    payload: response.data
                })
            }).catch((error) => {
                console.error(error)
                dispatch(stopButtonSpinner(id,"activate"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error.response.data.error) }
                })
                // this.setState({
                //     error: getApiErrorMessage(error.response.data.error)
                // })
                //window.location.href = `#${NAVIGATE_MEMBER_LOGIN}?error=activation_error`
            })
    }
}