import {
  REQUEST_CLAIM,
  FETCH_CLAIM_SUBMIT_CONFIG
} from './actions';

export function claimsubmitReducer(state, action) {
  const { type, payload } = action;
  if(!state) state = {}
  switch (type) {
    case REQUEST_CLAIM:
      return {
        ...state,
        newClaimRequest: payload
      }
    default:
      return state;
  }
}