import { doPost } from '../../../components/common/utils/api'
import { _URL_RETRIEVE_ACTIVITY_DETAIL } from '../../common/config/config';
import { startApiLoading, stopApiLoading } from '../../common/components/fieldbank/loader/action';

export const CONFIG_MY_ACTIVITY_SUCCESS = "CONFIG_MY_ACTIVITY_SUCCESS";
export const CONFIG_MY_ACTIVITY_FAILURE = "CONFIG_MY_ACTIVITY_FAILURE";
export const RETRIEVE_ACTIVITY_DETAIL_SUCCESS = "RETRIEVE_ACTIVITY_DETAIL_SUCCESS";
export const RETRIEVE_ACTIVITY_DETAIL_FAILURE = "RETRIEVE_ACTIVITY_DETAIL_FAILURE";
export const MY_ACTIVITY_AUTHENTICATE_TRANSACTION_SUCCESS = "MY_ACTIVITY_AUTHENTICATE_TRANSACTION_SUCCESS";
export const MY_ACTIVITY_AUTHENTICATE_TRANSACTION_FAILURE = "MY_ACTIVITY_AUTHENTICATE_TRANSACTION_FAILURE";
export const MY_ACTIVITY_LOGOUT_TRANSACTION_SUCCESS = "MY_ACTIVITY_LOGOUT_TRANSACTION_SUCCESS";
export const MY_ACTIVITY_LOGOUT_TRANSACTION_FAILURE = "MY_ACTIVITY_LOGOUT_TRANSACTION_FAILURE";

/**
 * Action call to retrieve activity details.
 * @param {JSON} payload Request payload to be dispatched
 * @author Ajmal Aliyar
 */
export const actionRetrieveActivityDetails = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("actionRetrieveActivityDetails"))
        await doPost(_URL_RETRIEVE_ACTIVITY_DETAIL, payload)
            .then((response) => {
                dispatch(stopApiLoading("actionRetrieveActivityDetails"))
                dispatch({
                    type: RETRIEVE_ACTIVITY_DETAIL_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("actionRetrieveActivityDetails"))
                dispatch({
                    type: RETRIEVE_ACTIVITY_DETAIL_FAILURE,
                    payload: error
                })
            })
    };
}

// /**
//  * Action call to authenticate transaction.
//  * @param {JSON} payload Request payload to be dispatched
//  * @author Ajmal Aliyar
//  */
// export const actionAuthenticateTransaction = (payload) => {
//     return async (dispatch) => {
//         dispatch({
//             type: LOADING_SPINNER,
//             payload: ENABLE_LOADING
//         })
//         dispatch({
//             type: LOADING_SPINNER,
//             payload: ENABLE_LOADING
//         })
//         await doPost(_URL_TRANSACTION_AUTHENTICATE, payload)
//             .then((response) => {
//                 dispatch({
//                     type: LOADING_SPINNER,
//                     payload: DISABLE_LOADING
//                 })
//                 dispatch({
//                     type: MY_ACTIVITY_AUTHENTICATE_TRANSACTION_SUCCESS,
//                     payload: response.data
//                 })
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: MY_ACTIVITY_AUTHENTICATE_TRANSACTION_FAILURE,
//                     payload: error
//                 })
//                 dispatch({
//                     type: LOADING_SPINNER,
//                     payload: DISABLE_LOADING
//                 })
//             })
//     }
// }

// /**
//  * Action call to logout transaction.
//  * @param {JSON} payload Request payload to be dispatched
//  * @author Ajmal Aliyar
//  */
// export const actionLogoutTransaction = (payload) => {
//     return async (dispatch) => {
//         dispatch({
//             type: LOADING_SPINNER,
//             payload: ENABLE_LOADING
//         })
//         await doPost(_URL_TRANSACTION_LOGOUT, payload)
//             .then((response) => {
//                 dispatch({
//                     type: MY_ACTIVITY_LOGOUT_TRANSACTION_SUCCESS,
//                     payload: response.data
//                 })
//                 dispatch({
//                     type: LOADING_SPINNER,
//                     payload: DISABLE_LOADING
//                 })
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: MY_ACTIVITY_LOGOUT_TRANSACTION_FAILURE,
//                     payload: error
//                 })
//                 dispatch({
//                     type: LOADING_SPINNER,
//                     payload: DISABLE_LOADING
//                 })
//             })
//     }
// }
