import { withTranslation } from 'react-i18next';
import { withSuspense } from '../../common/utils'
import { Component } from "react";
import React from 'react';
import { getItemFromBrowserStorage, BROWSER_STORAGE_KEY_PROGRAM_TYPE } from "../../common/utils/storage.utils";
import { PROGRAM_TYPE_INDIVIDUAL } from "../../common/utils/Constants";

class _503 extends Component {

    render() {
        const { t } = this.props
        const programType = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_PROGRAM_TYPE)
        return (
            <main role="main" className="container page__dashboard">
                <div className="container maintenance">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="error-template mt-5">
                                <span className="header h1">{t('errorPage.maintenance_title')}</span>
                                {/* <span className="message h3">Maintenance</span> */}
                                <div className="error-details mb-5 text-center w-50">{t('errorPage.maintenance_description')}</div>
                                {/* <div className="error-actions">
                                    <a href={`#${programType===PROGRAM_TYPE_INDIVIDUAL?NAVIGATE_MEMBER_DASHBOARD:NAVIGATE_CORPORATE_OVERVIEW}`} className="btn btn-primary btn-lg">
                                        <span className="glyphicon glyphicon-home"></span>
                                        Go to Dashboard
                                        </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
export default withSuspense()(withTranslation()(_503))