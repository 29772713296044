export const RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS = "RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_SUCCESS";
export const RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_ERROR = "RETRIEVE_RENEW_EXPIRY_POINT_DETAILS_ERROR";

export const RETRIEVE_QUOTE_SUCCESS = "RETRIEVE_QUOTE_SUCCESS";
export const RETRIEVE_QUOTE_ERROR = "RETRIEVE_QUOTE_ERROR";

export const RENEW_POINT_PAYMENT_SUCCESS = "RENEW_POINT_PAYMENT_SUCCESS";
export const RENEW_POINT_PAYMENT_ERROR = "RENEW_POINT_PAYMENT_ERROR";

export const TAB_EXPIRING_SOON = "expiringSoon"
export const TAB_EXPIRED = "expired"
export const EMPTY_STRING = ""
export const ONE_ITEM = 1
export const ID_SPINNER_PROCEED_TO_PAY = "id-proceed-to-pay"
export const PAYMENT_EQ_SUCCESS = "payment=success"
export const PAYMENT_EQ_CANCELLED = "payment=cancelled"
export const PAYMENT_EQ_FAILED = "payment=failed"