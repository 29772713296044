import { doPost } from '../../common/utils/api';
import { 
    _URL_CMS_HEADER_FOOTER_CONTENT
} from '../../common/config/config';
import { startApiLoading, stopApiLoading } from '../../common/components/fieldbank/loader/action';
import { getApiErrorMessage } from '../../common/utils';

export const ERROR = 'ERROR';
export const SET_FOOTERDETAILS = "SET_FOOTERDETAILS";


export const getFooterDetails = (payload) => {
    return async dispatch => {
        dispatch(startApiLoading("getFooterDetails"))
        await doPost(_URL_CMS_HEADER_FOOTER_CONTENT, payload)
            .then((response) => {
                dispatch(stopApiLoading("getFooterDetails"))
                dispatch({
                    type: SET_FOOTERDETAILS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch(stopApiLoading("getFooterDetails"))
                dispatch({
                    type: ERROR,
                    payload: { error: getApiErrorMessage(error) }
                })
            })
    }
}