import { CHANGE_EMAIL } from "./Constants";

/**
 * Reducer for change email
 * @param {Object} state Initialised as empty object
 * @param {Object} action Object that contains request payload and action
 * @author Amrutha J Raj
 */
export function changeEmailReducer(state, action) {
    const { type, payload } = action;
    if (!state) state = {}
    switch (type) {
        case CHANGE_EMAIL:
            return {
                changeEmail: payload
            }
        default:
            return state;
    }

}